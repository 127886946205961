.myclasses{
    height: 91px !important;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 15%) !important;
    border: none;
    outline: none;
    /* display: flex; */
    align-items: center;
    margin-left: 50px;
    margin-right: 35px;
    /* overflow-x: auto; */
}
/* .myclasses::-webkit-scrollbar { width: 0 !important }
.myclasses { overflow: -moz-scrollbars-none; }
.myclasses { -ms-overflow-style: none; } */

.myclasses_info{
    width: 250px !important;
    height: 52px;
    flex-shrink: 0;
    background: rgba(15, 147, 168, 0.22);
    border: 1px solid #0F93A8;
    box-sizing: border-box;
    border-radius: 15px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px !important;
    margin-right:20px !important;
    /* margin-left: 20px !important; */
}
.myclass_info_sel{
    background:#0F93A8 !important
}
.myclass_info_sel >span {
    color: #FFFFFF;
}
.myclasses_name{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;

    color: #0F93A8;
}
.myclasses_present{
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #0F93A8
}
.slick-slide{
    padding: 0px 10px !important;
}
.class_code{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 50%;
    margin: auto;
    margin-top: 20px;
}
.code_icon{
    color: #5CE0D2;
    cursor: pointer;
}
.class_table{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    width: 70%;
    height: max-content;
    background: #FFFFFF !important;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: auto;
}
.class_table > .MuiPaper-root > .MuiTable-root >thead >tr > .MuiTableCell-root {
    border-bottom: 10px solid white;
    color: black !important;
    font-weight: 600 !important;
    font-size: 15px !important;
}
.class_table > .MuiPaper-root > .MuiTable-root >tbody >tr > .MuiTableCell-root {
    border-bottom: 10px solid white;
    color: black !important;
    font-weight: 600 !important;
    font-size: 15px !important;
}
.code{
    font-weight: 600;
    color: red !important;
    background: #d1d1d1;
    border-radius: 10px;
    padding: 6px;
}
.shareModal{
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: space-evenly;
    width: 40%;
    margin-top: 15px;
}
@media only screen and (max-width: 600px) {
    .class_table{
        width: 90%;
    }
    .myclasses_name{
        font-size: 13.5px !important;
    }
    .myclasses_present{
        font-size: 13.5px !important;
    }
    .class_code{
        width: 100%;
    }
    .shareModal {
        width: 100%;
    }
    .myclasses{
        box-shadow: 0px 0px 8px rgb(0 0 0 / 15%) !important;
    }
    .myclasses_info{
        margin: 20px !important;
        margin-right: 9px !important;
        margin-left: 0px !important;
    }
    .menu_option_extend {
        height: 120px;
    }
    .myclasses_info{
        width: 115px !important;
    }
}