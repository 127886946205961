.lp-popup-modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.218);
  z-index:3
}

.lp-popup-body {
  text-align: center;
  width: 85%;
  max-width: 400px;
  padding: 5%;
  margin: auto;
  border-radius: 20px;
  background-color: #ffffff;
  transition: all 0.4s;
}

.lp-popup-body > h5 {
  font-size: 1.3rem;
}
.lp-popup-body > .Success {
  color: yellowgreen;
}
.lp-popup-body > .Error {
  color: orangered;
}

.lp-popup-body > p {
  font-size: 1.2rem;
  color: #414141;
  margin: 20px 0;
}

.lp-popup-modal > div > .buttons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.lp-popup-body > .buttons > button {
  padding: 10px 20px;
  border-radius: 9px;
  border: transparent;
}
