/* Model */
.filterAnalyticsModal .filterAnalyticsModalBtnApply {
  background-color: #5ce0d2;
}

.filterAnalyticsModal .filterAnalyticsModalBtnCancel {
  background-color: #ffff;
  color: black;
  border: 2px solid #5ce0d2;
}
