.taskTopic .box {
    min-height: 100px;
    background-color: #5ce0d2;
    padding: 25px;
    border-radius: 8px;
}

.task_details {
    border: 2px solid black !important;
    box-shadow: none !important;
    border-radius: 10px !important;
}