#pdf-viewer {
  width: 90%;
  margin: 25px auto;
}
@media screen and (max-width: 500px) {
  #pdf-viewer {
    width: 90%;
    margin: 25px auto;
  }
  video {
    width: 90%;
  }
}
