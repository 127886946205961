.modules .box .upperBox {
  min-height: 150px;
  background-color: #5ce0d2;
  padding: 25px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
}
.whiteDiv > h5 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
}
.whiteDiv > p {
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
}
.modules .box .upperBox .createTest {
  color: black;
}

@media (min-width: 768px) {
  .modules .MuiTabs-flexContainer {
    justify-content: center;
  }
}

/* Model */
.moduleTaskTypeModal .moduleTaskTypeModalBtnApply {
  background-color: #5ce0d2;
}

.moduleTaskTypeModal .moduleTaskTypeModalBtnCancel {
  background-color: #ffff;
  color: black;
  border: 2px solid #5ce0d2;
}

.assessmentTaskTypeModal .assessmentTaskTypeModalBtnApply {
  background-color: #5ce0d2;
}

.assessmentTaskTypeModal .assessmentTaskTypeModalBtnCancel {
  background-color: #ffff;
  color: black;
  border: 2px solid #5ce0d2;
}
.MuiTabs-indicator {
  border: 4px solid #0f93a8;
  border-radius: 23px;
  /* width: 53px !important;
  left:  600px !important; */
}