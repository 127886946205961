.learningResources {
  display: flex;
  width: inherit;
}
.select__indicator-separator {
  display: none !important;
}

.test_list_filter {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
}

.MuiOutlinedInput-input {
  padding: 9px !important;
}

#test_filter_date_picker-label {
  margin-top: -10px !important;
}

.table_container {
  display: flex;
  width: 100% !important;
  flex-direction: column;
  box-shadow: 0px 0px 7px 0px #0000005e;
  border-radius: 10px;
  padding: 10px;
}

.search_container {
  width: 100%;
  text-align: right;
  margin-right: 0.5%;
  margin-top: 0.5%;
}

#searchbar .MuiInput-underline::after {
  border: 1px solid #dbf3f2;
}

@media screen and (max-width: 450px) {
  .learningResources {
    padding: 0px !important;
  }
  .test_list_filter {
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .test_list_filter .select__value-container {
    width: 250px !important;
  }
  .test_list_filter .makeStyles-select-7,
  .test_list_filter .makeStyles-datePicker-8 {
    width: fit-content !important;
    margin-bottom: 7px;
  }
  .test_list_filter .makeStyles-7 {
    padding: 0 !important;
  }
}
