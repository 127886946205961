.language_button_container {
  display: flex;
  font-family: "Poppins" !important;
}

.language_button {
  width:fit-content;
  height: 40px;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  border: none;
  background: rgba(224, 224, 224, 1);
  transition: 0.3s;
}

.language_button:hover {
  transform: scale(1.05);
}

.selected_language {
  background: rgba(92, 224, 210, 1);
}

.language_button:nth-child(2) {
  margin-left: 8px;
}
/* .dropdown-menu-mobile{
    list-style: none !important;
    width: 60% !important;
    position: relative;
    top: 10px;
    left: -6px;
}
.dropdown-menu-mobile > li >.dropdown-item{
    position: relative;
    right: 42px;
    font-size: 13px;
}
.style90{
    width: 90px !important;
} */
.profileSidebar {
  display: flex;
  align-items: center;
}
.sidebarProfiletext {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.sidebarProfiletext > a > span {
  width: 50px;
  height: 20px;
  background: #cef6f2;
  border-radius: 4px;
  font-size: 10px;
  align-items: center;
  margin: auto;
  display: flex;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  color: black !important;
}
.sidebarProfiletext > a > span:hover,
.sidebarProfiletext > a > span:active {
  background: #5ce0d2;
  color: #fff;
}
.sidebarProfiletext > a {
  align-items: center;
  margin: auto;
}
.sidebarProfiletext > a > .sidebar_settings {
  display: flex;
  align-items: center;
  margin: auto;
  color: #5ce0d2;
  cursor: pointer;
}
.rounded-circle {
  width: 50px !important;
  height: 50px !important;
}
