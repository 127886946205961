.module .cardData {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@keyframes slow-bounce {
  0%, 100% {
    transform: translateY(0);
    box-shadow: 0 5px 15px rgba(92, 224, 210, 0.5); /* Shadow with 50% opacity */
  }
  50% {
    transform: translateY(-20px);
    box-shadow: 0 15px 25px rgba(92, 224, 210, 0.2); /* Shadow with 20% opacity */
  }
}

.bounce {
  animation: slow-bounce 2s ease-in-out infinite;
}

.cardData {
  background-color: white;
  color: black;
  margin-top: -48px;
  border-radius: 0px 0px 17px 17px;
  border-top: 1px solid rgba(0, 0, 0, 0.288);
}
.boxShadowCard {
  border: 1px solid #5ce0d2;
  box-shadow: 0px 1px 7px #5ce0d2;
}
.boxShadowCard > .text-center > h5 {
  font-size: 15px;
}
.boxShadowCard > .text-center > .text-dark {
  background: rgba(92, 224, 210, 0.5);
  border: 1px solid #2ba99c;
  box-sizing: border-box;
  border-radius: 16px;
  width: 45%;
  margin: auto;
  font-size: 12px;
}
.cardData p:first-child {
  margin-block-end: 10px;
}

.evenly {
  margin-top: 0px !important;
}

.module .dropdown .iconColor {
  color: white;
}

/* MODAL */

.moduleContentUploadModal .moduleContentUploadModalBtnApply {
  background-color: #5ce0d2;
}

.moduleContentUploadModal .moduleContentUploadModalBtnCancel {
  background-color: #ffff;
  color: black;
  border: 2px solid #5ce0d2;
}

.modal_button_container {
  padding: 0.5rem !important;
  padding-bottom: 2rem !important;
}

@media screen and (max-width: 420px) {
  .my_resources_heading_section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .my_resources_heading_section div {
    width: 50%;
  }
  .modal_button_container {
    padding-bottom: 3rem !important;
  }
}
