.createTest .questionTitle {
  width: 90%;
}

.createTest .questionOption {
  width: 75%;
}

.createTest .closeQuestionBtn {
  margin-left: 340px;
  position: relative;

  bottom: 19px;
}

@media only screen and (max-width: 600px) {
  .createTest .questionTitle {
    width: 82%;
  }

  .createTest .questionOption {
    width: 72%;
  }

  .createTest .closeQuestionBtn {
    margin-left: 290px;
    bottom: 70px;
  }
}

/* Buttons */
.createTest .saveFormBtn {
  background-color: #5ce0d2;
  width: 220px;
}

.createTest .cancelFormBtn {
  background-color: #ffff;
  width: 220px;
  color: black;
  border: 2px solid #5ce0d2;
}

.createTest .saveFormBtn:hover {
  background-color: #5ce0d2;
}

.createTest .cancelFormBtn:hover {
  background-color: #ffff;
  color: black;
  border: 2px solid #5ce0d2;
}
