.studentanalytics_mini_cards {
  display: flex;
  width: 100% !important;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 1vh;
  margin-top: 10px !important;
}
.student_mini_card {
  display: flex;
  max-width: 130px !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-top: 2%;
  height: auto;
  min-height: 80px;
  box-shadow: 0px 4px 90px 0px rgba(163, 171, 185, 0.24);
}
.student_analytics_mini_cards_section {
  display: flex;
  flex-direction: row;
  max-width: 500px !important;
  justify-content: space-evenly;
}
.student_analytics_mini_cards_section:first-child {
  margin-right: -3rem;
}
.student_analytics_mini_cards_section:last-child {
  margin-left: -3rem;
}
.student_analytics_questionReport {
  margin-left: 85%;
  margin-bottom: 20px;
  background-color: #5ce0d2b8 !important;
  color: black !important;
  font-weight: bold;
  border: none !important;
}
@media screen and (max-width: 1100px) {
  .analytics_card_list {
    right: 20px;
    top: 20px !important;
  }
}
@media screen and (max-width: 1440px) {
  .student_analytics_questionReport {
    margin-left: 80%;
  }
}
@media screen and (max-width: 905px) {
  .student_analytics_mini_cards_section:first-child {
    margin-right: 0rem;
  }
  .student_analytics_mini_cards_section:last-child {
    margin-left: 0rem;
  }
}
@media screen and (max-width: 400px) {
  .studentanalytics_mini_cards {
    flex-direction: column;
  }
  .student_mini_card {
    margin-left: 0px;
    margin-bottom: 5px;
    width: 100px !important;
  }
}
@media screen and (max-width: 375px) {
  .student_analytics_questionReport {
    margin-right: 5%;
  }
}
@media screen and (max-width: 540px) {
  .student_analytics_questionReport {
    margin-left: 50% !important;
  }
}
