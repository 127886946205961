.authorQues {
  display: flex;
  flex-direction: column;
  border-radius: 9px;
  border: 2px dashed #0f93a8;
  padding: 10px 15px;
  /* box-shadow: 0px 4px 7px rgb(33 33 33 / 18%); */
}
.authorQues_head {
  display: flex;
}
.authorQues_head__left {
  display: flex;
  flex-direction: column;
}
.authorQues_head__right {
  display: flex;
  flex-direction: column;
}
.attach_icon {
  transform: rotate(230deg);
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 4px;
  width: 0.9em !important;
  opacity: 0.5;
}
.check_icon {
  position: absolute;
  right: 15px;
  top: 12px;
  width: 0.9em !important;
  opacity: 1;
  font-size: 35px;
  fill: green;
}
.input-attach-group {
  width: 90% !important;
}

.input-attach-group > .form-control {
  padding: 0.75rem 0.75rem !important;
  padding-right: 2em !important;
  border: 1px solid #f1f1fa;
  border-radius: 16px !important;
}

.add_option_section {
  display: flex;
}

.add_option_section > .input-attach-group {
  width: 85% !important;
}

.add_option_section > .form-check > .form-check-input {
  width: 1.2em;
  height: 1.2em;
  margin-top: 15px;
  margin-right: 15px;
  border: 3px solid #0f93a8;
}
/* .form-check-input[type="radio"] {
    border-radius: 0%!important;
    -webkit-appearance: checkbox!important; 
    -moz-appearance: checkbox!important;   
    -ms-appearance: checkbox!important;  
} */
.cancelOption {
  margin-top: 10px;
  margin-left: 10px;
  color: #91919f;
}

.btn-add-option {
  background-color: rgba(14, 154, 171, 0.14) !important;
  border-radius: 16px !important;
  color: #0e9aab !important;
  padding: 8px 25px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-bottom: 30px;
}
.textQues {
  overflow-y: hidden;
  /* margin-left: 10px; */
  /* overflow:auto; */
  resize: none;
  white-space: initial !important;
  word-wrap: break-word !important;
}
.ico-block {
  border: 2px solid #0f93a8;
  margin-right: 5px;
  width: 0.9em !important;
  height: 0.9em !important;
  border-radius: 5px;
}

.option-dropdown {
  width: 75%;
  margin-left: 20%;
}

.btn-question-type {
  padding: 0.65rem 0.75rem !important;
  border: 1px solid #f1f1fa !important;
  border-radius: 16px !important;
  width: 100%;
  text-align: left !important;
  color: #91919f !important;
  font-size: 16px !important;
}

.btn-question-type::after {
  position: absolute;
  border-top: 0.4em solid !important;
  border-right: 0.4em solid transparent !important;
  border-left: 0.4em solid transparent !important;
  /* top: 20px; */
  right: 10px;
}

.option-dropdown > .dropdown-menu {
  width: 100%;
  /* padding: 0rem!important; */
}

.option-dropdown > .dropdown-menu > .dropdown-item {
  width: 100%;
  padding: 0.5rem 0.5rem;
  color: #91919f !important;
  border-bottom: 1px solid #91919f14 !important;
  font-weight: 100;
}

.opt-edit {
  float: right;
  margin-top: 12px;
}

.round-btn {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #ddf1f3;
  color: #ddf1f3;
  padding: 10px;
  margin-right: 10px;
}

.btn-add-question {
  background-color: #0f93a8 !important;
  border-radius: 16px !important;
  color: #ffffff !important;
  padding: 10px 25px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-bottom: 30px;
}

.btn-add-question > .ico-block {
  border: 2px solid #ffffff !important;
  margin-right: 5px;
  width: 0.9em !important;
  height: 0.9em !important;
  border-radius: 5px;
}
.submit_author_question {
  text-align: center;
}
.authorQues_marks {
  width: 127px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #f1f1fa;
  box-sizing: border-box;
  border-radius: 10px !important;
  display: flex;
  margin-left: 20%;
  margin-bottom: 50px;
}
.authorQues_marks > span {
  display: flex;
  flex: 50% 1;
  align-items: center;
  color: #91919f;
  font-size: 12px;
  justify-content: center;
  text-align: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.styleRadio > input[type="radio"] {
  appearance: none;
  border: 1px solid #d3d3d3;
  width: 30px;
  height: 30px;
  content: none;
  outline: none;
  margin: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0% !important;
}

.styleRadio > input[type="radio"]:checked {
  appearance: none;
  outline: none;
  padding: 0;
  content: none;
  border: none;
}
.styleRadio > .form-check-input:checked[type="radio"] {
  background-image: none !important;
}
.styleRadio > input[type="radio"]:checked::before {
  position: absolute;
  color: white !important;
  content: "\00A0\2713\00A0" !important;
  border: none;
  font-weight: bolder;
  font-size: 12px;
  margin: 0px 0px 0px 1px;
}
