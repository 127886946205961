.dashboard .box .upperBox {
  height: 200px;
  background-color: #5ce0d2;
  padding: 25px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
/* .dashboard {
  margin-top: 60px !important;
} */
.MuiTab-root {
  text-transform: none !important;
  /* font-weight: bold !important; */
}
.MuiPaper-elevation1 {
  background-color: white !important;
}
.MuiTabs-root > .MuiButtonBase-root {
  display: none;
}
#classesModal > .modal-dialog > .modal-content {
  width: 100% !important;
}
.class-subject-text h1,
.class-subject-text h5 {
  color: white;
  /* transition: 0.2s; */
}

/* .class-subject-text h1:hover,
.class-subject-text h5:hover {
  color: white;
} */
/* .fixScroll {
  overflow-x: hidden;
} */
.paperRight {
  margin-top: 50%;
}
.dashboardInfo {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .dashboard .MuiTabs-flexContainer {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .announcement_list {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .inputBox div div {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .inputBox div div div:nth-child(2) {
    width: 100%;
  }
  .inputField {
    width: 100% !important;
  }
  .inputField label {
    margin: 0px auto;
    width: 100%;
    font-family: "Poppins";
  }
  .dashboardInfo > .class-subject-text {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .dashboardInfo > .detailsDashboard {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }
  .paperRight {
    margin-top: 40% !important;
    display: flex;
    margin-left: 0% !important;
  }
  .imgScaleSize {
    width: 18px !important;
    height: 18px !important;
  }
}

.paperRight {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 17%;
}
.imgScaleSize {
  width: 20px;
  height: 20px;
}
.paperRight > span {
  margin-bottom: 10px;
}
/* Model */

.classModel .classModelBtnApply {
  background-color: #ffffff;
  color: #5ce0d2 !important;
  width: 150px;
  border: 3px solid #5ce0d2;
  box-sizing: border-box;
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
}
.classModelBtnApply:hover {
  background-color: #5ce0d2;
  color: white !important;
}
#classesModalLabel {
  text-align: center;
  margin: 0px auto;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  color: #5ce0d2;
}
.classModel > .modal-dialog > .modal-content {
  background: #ffffff !important;
  border: 4px solid #5ce0d2 !important;
  border-radius: 23px !important;
}
.classModel > .modal-dialog > .modal-content > .modal-header {
  border-bottom: 1px solid #5ce0d2;
}
.classModel
  > .modal-dialog
  > .modal-content
  > .modal-body
  > .row
  > .col-sm-3
  > .form-check
  > .form-check-input {
  /* background-color: #5ce0d2; */
  border: 3px solid #5ce0d2;
}
.classModel
  > .modal-dialog
  > .modal-content
  > .modal-body
  > .row
  > .col-sm-3
  > .form-check
  > .form-check-input:checked[type="radio"] {
  background-color: #5ce0d2;
}
.classModel
  > .modal-dialog
  > .modal-content
  > .modal-body
  > .row
  > .col-4
  > .form-check
  > .form-check-input {
  /* background-color: #5ce0d2; */
  border: 3px solid #5ce0d2;
}
.classModel
  > .modal-dialog
  > .modal-content
  > .modal-body
  > .row
  > .col-4
  > .form-check
  > .form-check-input:checked[type="radio"] {
  background-color: #5ce0d2;
}
/* .classModel > .classModelBtnApply:hover {
    color: black !important;
} */
.classModel > .modal-dialog > .modal-content {
  width: 100% !important;
}
.subjectModal > .modal-dialog > .modal-content {
  width: 100% !important;
}
.classModel .classModelBtnCancel {
  background-color: #ffff;
  color: black;
  border: 2px solid #5ce0d2;
}

.subjectModal .subjectModalBtnApply {
  background-color: #5ce0d2;
  transition: 0.25s;
}

.subjectModal .subjectModalBtnApply:hover {
  color: white;
}

.subjectModal .subjectModalBtnCancel {
  background-color: #ffff;
  color: black;
  border: 2px solid #5ce0d2;
}
.modal-body > .row > .col-sm-3 > .form-check > .form-check-label {
  width: 60px;
}
.dashboard_tabs {
  display: none !important;
}
.modal-body > .row > .col-sm-3 > .form-check > .form-check-label {
  width: 60px;
  font-size: 16px;
  font-weight: 600;
}
.dashboard_container {
  padding: 0px !important;
}
