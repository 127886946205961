.lp-form {
  width:100%;
  max-width: 400px;
  margin: auto;
}

.lp-form-grid {
  display: grid;
  grid-template-columns: 47% 47%;
  grid-gap: 6%;
}

.lp-form-button {
    display: block;
    width:fit-content;
    padding:10px 20px;
    margin: 20px auto;
    font-size:0.8rem;
    color: white;
    background-color: #5ce0d2;
    border-radius:12px;
    border: transparent;
}