.superhead {
  display: flex;
  flex-direction: column;
}

.tf__head {
  display: flex;
  flex-direction: column;
  border-radius: 9px;
  box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
  border-radius: 15px;
}
.tf {
  display: flex;
  flex-wrap: wrap;
  padding-left: 30px;
  margin: inherit;
  align-items: center;
  margin-bottom: 20px;
}
.tfCard {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.tfCard__left {
  display: flex;
  flex: 2 1;
  padding-left: 18px;
  align-items: flex-start;
  word-break: break-word;
  font-size: 24px;
  font-weight: 600;
}
.tfCard__right {
  margin-top: 16px;
  /* margin-right: 10px; */
  margin-right: 10px;
}
.tfCard__right > svg {
  height: 24px;
  width: 24px;
  margin: 0 5px;
}

.imgBlock1 {
  background-size: cover !important;
  position: relative;
  overflow: hidden;
  min-width: 130px;
  min-height: 100px;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: -34px;
}

.main_div {
  display: flex;
}
.tf_image {
  height: 300px;
  width: 300px;
  border-radius: 15px;
  margin-left: 25px;
  margin-top: 25px;
}
.ans_tf_image {
  width: 130px;
  height: 100px;
  border-radius: 10px;
}
@media (max-width: 450px) {
  .main_div {
    flex-direction: column;
  }
  .tf > .up {
    width: 100% !important;
  }
  .top-left,
  .top-right {
    display: inline !important;
  }
  .tf {
    width: 95% !important;
    flex-direction: column;
  }
  /* .top-left {
    margin-left: -15px !important;
  } */
  .tfCard__left {
    display: flex;
    flex: 2 1;
    padding-left: 18px;
    align-items: flex-start;
    word-break: break-word;
    font-size: 16px;
    font-weight: 600;
  }

  /* .top-right{
    padding-right: 1px!important;
    margin-left: 6px!important;
  } */

  .imgBlock {
    margin-left: auto;
  }
}
