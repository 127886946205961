.check-outer {
  display: flex;
  width: 10px;
  height: 10px;
  padding: 2px;
  border: 2px solid #5ce0d2;
  border-radius: 100%;
  margin: auto 20px auto 0;
  cursor: pointer;
}

.check-outer > .check-inner {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  margin: auto;
}

.check-outer-selected > .check-inner {
  background: #5ce0d2;
}

.check-outer-square {
  border-radius: 4px;
}

.check-outer-square > .check-inner {
  border-radius: 2px;
}
