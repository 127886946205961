.lessonPlans {
  width: 100%;
}

.lessonPlan {
  box-sizing: content-box;
  width: 100%;
  padding: 5% 0;
  margin: auto;
  border-bottom:1px solid #F8F8F8; 
}
.lessonPlan-wrapper {
  background-color: #ffffff;
  padding:2.5% 0 !important;
}
.lessonPlan-wrapper > .plan-detail {
  height: fit-content;
}
.lessonPlan > .plan-detail {
  display: flex;
  justify-content: space-between;
}

.lessonPlan > .plan-detail > p {
  flex: 1;
  color: #4d4d4d;
  font-size: 0.9rem;
}

.lessonPlan > .plan-detail > span {
  background: #ffcf22;
  width: fit-content;
  white-space: nowrap;
  color: #ffffff;
  font-size: 0.8rem;
  padding: 3px 8px;
  margin: 0 0 auto 0;
  border-radius: 20px;
  height: fit-content;
}
.lessonPlan-wrapper > .plan-detail > span {
  background-color: #00B68A;
}

.plan-action {
  display: flex;
  justify-content: space-between;
  margin-top:20px;
}

.plan-date {
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  padding: 2px 8px;
  border-radius: 18px;
  background: #efefef;
}

.plan-date > img {
  margin: auto 5px auto 0;
  height: fit-content;
}
.plan-date > label {
  display: block;
  font-size: 0.8rem;
  color: #4d4d4d;
  margin: auto 0;
  height: fit-content;
}

.actions {
  width: 90px;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}
.actions > img {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

@media only screen and (min-width:700px) {
  .lessonPlan {
    max-width: 600px;
    padding:20px 0;
  }
}