@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
body::before {
  display: block;
  content: "";
  height: 60px;
}

html {
  height: 100%;
}
body {
  /*position: static !important;*/
  height:100%;
}
#root {
  height:auto;
}

* {
  font-family: "Poppins", sans-serif;
}

.dropdown-item:active {
  background-color: #c4c7ca !important;
}

a:hover {
  color: black !important;
}

.MuiTableCell-root,
.MuiTableCell-head {
  font-family: "Poppins" !important;
}

.MuiTableCell-head {
  color: #4fb8ad !important;
  font-weight: bold;
}
.TeacherName {
  margin-right: 10px;
}
.teacherDetails {
  display: flex;
  align-items: center;
}
.card-radius {
  border-radius: 17px !important;
  box-shadow: #00000040 0px 0px 5px 1px;
}
.toggleClass {
  display: block;
  margin-left: -90px;
}
.card-height {
  min-height: 190px !important;
}

.card-img {
  height: 130px;
  padding: 5px;
}

.card {
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.card:hover {
  transform: scale(1.1);
}

.card-thumbnail {
  height: 100%;
  width: 100%;
  object-fit: fill;
  border-radius: 10px;
}

.card-height p {
  margin-bottom: 2px !important;
}
.dateNavBar {
  display: none;
}
@media screen and (max-width: 500px) {
  .card-img {
    height: 110px;
  }
  .TeacherName {
    display: none;
  }
  .card-height {
    min-height: 160px !important;
  }
  .MuiTabs-flexContainer {
    justify-content: center !important;
  }
  .dateNavBar {
    display: block;
    position: absolute;
  }
}

/* Change the font size and font family */
#google_translate_element {
  position: relative;
  padding: 25px 2.5% 10px 2.5%;
  overflow: hidden;
  background-color:  #edfcfa;
}
#google_translate_element .goog-te-gadget {
  width: 200px;
  overflow: hidden;
  margin: auto;
}
#google_translate_element select {
  width: 100%;
  font-size: 16px;
  color: #10a0b6;
  font-family: "Poppins", sans-serif;
  border: transparent;
  background: transparent;
}

/* Change the background color of the button */
#google_translate_element div.goog-te-gadget-simple {
  background-color: #f5f5f5;
}

/* Change the color of the button text */
#google_translate_element div.goog-te-gadget-simple span {
  color: #333;
}

/* Change the hover color of the button */
#google_translate_element div.goog-te-gadget-simple:hover {
  background-color: #333;
}

/* Change the hover color of the button text */
#google_translate_element div.goog-te-gadget-simple:hover span {
  color: #f5f5f5;
}

.goog-te-gadget > span:nth-child(2) {
  display: none !important;
}
.goog-te-banner-frame {
  display: none !important;
}

.skiptranslate > iframe {
  display: none;
}

@media only screen and (min-width: 1000px) {
  #google_translate_element {
    background-color: transparent;
    padding-bottom: 0;
  }
  #google_translate_element .goog-te-gadget {
    margin: auto 0 auto auto;
  }
}
