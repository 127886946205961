.superhead {
  display: flex;
  flex-direction: column;
}

.mmcq__head {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-radius: 9px;
  box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
  border-radius: 15px;
}
.mmcq {
  display: flex;
  flex-wrap: wrap;
  padding-left: 30px;
  margin: inherit;
}
.mmcqCard {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.mmcqCard__left {
  display: flex;
  flex: 2 1;
  padding-left: 18px;
  align-items: flex-start;
  word-break: break-word;
  font-size: 24px;
  font-weight: 600;
}
.mmcqCard__right {
  /* margin-top: 10px; */
  margin-top: 16px;
  margin-right: 10px;
}
.mmcqCard__right > svg {
  height: 24px;
  width: 24px;
  margin: 0 5px;
}
.main_div {
  display: flex;
}
.mmcq_image {
  height: 300px;
  width: 300px;
  border-radius: 15px;
  margin-left: 25px;
  margin-top: auto;
  margin-bottom: 30px;
  /* margin-bottom: auto; */
}
.ans_mmcq_image {
  width: 130px;
  height: 100px;
  border-radius: 10px;
}
/* .imgBlock {
  background-size: cover !important;
  position: relative;
  overflow: hidden;
  min-width: 130px;
  min-height: 100px;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: -34px;
} */
@media (max-width: 450px) {
  .main_div {
    flex-direction: column;
  }
  .mmcq > .up {
    width: auto;
  }
  .up {
    margin-top: 20px;
  }
  .mmcqCard__left {
    display: flex;
    flex: 2 1;
    padding-left: 18px;
    align-items: flex-start;
    word-break: break-word;
    font-size: 16px;
    font-weight: 600;
  }
  .top-left,
  .top-right,
  .bottom-left,
  .bottom-right {
    display: inline !important;
  }
  .mmcq {
    width: 95% !important;
    flex-direction: column;
    /* margin-left: auto!important;
      margin-right: auto!important; */
  }
  /* .top-left,
  .bottom-left {
    margin-left: -15px !important;
  } */
  /* .top-right,.bottom-right{
      padding-right: 1px!important;
      margin-left: 6px!important;
    } */

  /* .ml-6 {
    margin-left: 6px !important;
  } */
}
