.MuiBox-root {
  padding: 0 !important;
}
.assesment {
  display: flex;
  justify-content: center;
  padding-top: 25px;
}
.assesment__practise {
  height: 106px;
  /* width: 379px; */
  width: 24rem;
  left: 318px;
  top: 344px;
  margin: 0 10px;
}
.assesment__onlineTest {
  height: 106px;
  /* width: 379px; */
  width: 24rem;
  left: 318px;
  top: 344px;
  margin: 0 10px;
}
.assesment__practise__action {
  background-color: #71fba8;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #71fba8;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  justify-content: space-evenly;
}
.assesment__practise__action:hover {
  color: #000;
  transition: all 0.3s ease-in;
}
.assesment__practise__action > img {
  width: 46px;
  height: 69px;
}
.assesment__practise__action > span {
  font-size: 24px;
}
.assesment__onlineTest__action {
  background-color: #ff8cb0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #ff8cb0;
  display: flex;
  /* display: -webkit-inline-box; */
  align-items: center;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  /* display: flex; */
  align-items: center;
  justify-content: space-evenly;
  /* padding-right: 138px; */
}
.assesment__onlineTest__action:hover {
  color: #000;
  transition: all 0.3s ease-in;
}
.assesment__onlineTest__action > img {
  width: 61px;
  height: 61px;
  margin-left: -25px;
}
.assesment__onlineTest__action > span {
  font-size: 24px;
}
@media (max-width: 450px) {
  .assesment {
    flex-direction: column;
    align-items: center;
  }
  .assesment__onlineTest__action {
    display: -webkit-inline-box;
  }
  .assesment__practise {
    margin-bottom: 20px;
    width: 20rem;
  }
  .assesment__onlineTest {
    width: 20rem;
  }
  .assesment__onlineTest > span {
    margin-right: 10px;
    margin-left: 4px;
  }
  .assesment__onlineTest__action > img {
    margin-left: auto;
  }
  .assesment__onlineTest__action > svg {
    display: none;
  }
  .assesment__practise__action > svg {
    display: none;
  }
}
