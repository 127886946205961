.classanalytics_bar_card {
  display: flex;
  position: relative !important;
  width: 90% !important;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  margin-left: 30px;
  margin-bottom: 30px;
  padding: 10px;
  box-shadow: 0px 0px 7px 0px #00000040;
}
.classanalytics_section_layer {
  display: flex !important;
  flex-direction: row !important;
  width: 90% !important;
  margin-left: 5% !important;
  justify-content: space-evenly;
}
.classanalytics_card_list {
  display: flex;
  position: absolute;
  top: 325px;
  right: 75px;
  z-index: -1;
  flex-direction: column;
}
.classanalytics_bullet_list {
  display: flex;
  position: absolute;
  /* top: 325px; */
  bottom: 70%;
  right: 40%;
  z-index: -1;
  flex-direction: column !important;
}
.Class_Analytics_Responsive_Container {
  min-width: 400px !important;
  min-height: 400px !important;
}
@media screen and (max-width: 1100px) {
  .classanalytics_section_layer {
    flex-direction: column !important;
  }
  .classanalytics_bar_card {
    flex-direction: column;
  }
  .classanalytics_card_list {
    top: 275px;
    right: 50px;
  }
  .Class_Analytics_Responsive_Container {
    min-width: 200px !important;
    min-height: 200px !important;
  }
  .classanalytics_bullet_list {
    right: 20%;
    bottom: 35%;
  }
}
@media screen and (max-width: 540px) {
  .classanalytics_section_layer {
    margin-left: 0.5rem !important;
  }
}
@media screen and (max-width: 400px) {
  .classanalytics_card_list {
    top: 150px !important;
    right: 10px;
  }
  .classanalytics_section_layer {
    margin-left: 0.1rem !important;
  }
}
