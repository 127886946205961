.form-input {
  width: 100%;
  margin: 0;
}

.lock {
  opacity: 0.5;
}

.form-input > .label > label {
  white-space: nowrap;
  font-size: 0.8rem;
  color: #000000;
  font-family: "Poppins";
}

.form-input > .main-style {
  display: block;
  padding: 12px 10px;
  width: 100%;
  background: rgba(171, 171, 171, 0.13);
  border-radius: 14px;
  border: transparent;
  margin: 0;
  font-size: 0.9rem;
  font-family: "Poppins";
  color: #414141;
  cursor: pointer;
}

/*select style*/
.form-input > .select-style {
  padding: 10px;
  display: flex;
  position: relative;
}
.form-input > .select-style > span {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  width: 100%;
  margin: 0;
  color: #000000;
}
.form-input > .select-style > span > img {
  width: 20px;
  transform: rotate(90deg);
}
.form-input > .select-style > span > .active {
  transform: rotate(0deg);
}
.form-input > .select-style > .option-list {
  position: absolute;
  list-style: none;
  top: 105%;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 1;
  background: #ffffff;
  height: 0px;
  overflow-y: scroll;
}

.form-input > .select-style > .option-list > li {
  text-align: center;
  display: flex;
  font-size: 0.9rem;
  width: calc(100% - 20px);
  padding: 10px;
  white-space: nowrap;
  cursor: pointer;
  color: #414141;
  font-family: "Poppins";
}

/*.form-input > .select-style > .option-list > li:nth-child(1) {
  color: grey !important;
}*/

.form-input > .select-style > .active {
  height: 200px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
}

.form-input-option {
  display: flex;
}

/*Attachment Input UI*/
.attachment {
  display: flex;
  width: 30px;
  position: relative;
  width: fit-content;
}

.attachment > input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
}

.attachment > img {
  width: 20px;
  margin: auto;
}

/*form checklists*/
.form-checklists {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.form-checklists > .checklist {
  display: flex;
  width: fit-content;
  margin-right: 10px;
}
.form-checklists > .checklist > img {
  margin: auto 10px auto 0;
}
.form-checklists > .checklist > label {
  display: block;
  margin: auto 0;
  font-size: 0.9rem;
}

/*search form input*/
.search-form-input {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  width: 100%;
  background: rgba(171, 171, 171, 0.13);
  border-radius: 12px;
  border: transparent;
  margin: 0;
  font-size: 0.9rem;
  font-family: "Poppins";
  color: #414141;
  cursor: pointer;
}
.search-form-input > input {
  width:100%;
  padding:8px 0;
  outline-style: none;
  border: transparent;
  background-color: transparent;
}
