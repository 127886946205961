.practise_assessment_modal_head {
  /* margin-left: -8rem !important; */
  overflow-y: hidden !important;
}
.practise_assessment_modal_head > .modal-dialog > .modal-content {
  border-radius: 15px !important;
}
.practise_assessment_modal {
  display: flex;
  flex-direction: column;
  width: 102%;
}
.check_drop > .MuiIconButton-label > .MuiSvgIcon-root {
  color: #5ae893 !important;
  font-size: 36px !important;
  margin: 1px 3px 10px 0px;
}
.practise_assessment_modal_top {
  display: flex;
  flex-direction: column;
}
.practise_assessment_heading {
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 600;
}
.practise_assessment_heading > svg {
  margin-right: 10px;
}
.practise_assessment_fields {
  display: grid;
  grid-template-columns: 200px 200px 200px;
  gap: 10px;
}
.practise_assessment_fields > div > div {
  margin-bottom: 0 !important;
}
.practise_assessment_modal_mid {
  display: flex;
  align-items: center;
}
.practise_assessment_modal > .practise_assessment_modal_mid > div {
  margin-right: 16px !important;
}
.practise_assessment_modal_bot {
  display: flex;
  align-items: center;
  justify-content: center;
}
.practise_assessment_modal_cancel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: #cdffe1;
  border-radius: 16px;
  border: 1px solid #cdffe1;
  width: 124px;
  height: 56px;
  color: #1eb85c;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-right: 18px;
}
.practise_assessment_modal_cancel:hover {
  color: #1eb85c;
  background: #fff;
  transition: all 0.3s ease-in;
}
.practise_assessment_modal_save {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: #56e58f;
  border-radius: 16px;
  border: 1px solid #56e58f;
  width: 124px;
  height: 56px;
  color: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}
.practise_assessment_modal_save:hover {
  color: #56e58f;
  background: #fff;
  transition: all 0.3s ease-in;
}
.practise_assessment_modal_head > .modal-dialog > .modal-content > .modal-body {
  border-radius: 15px;
}
.online_test_modal_head > .modal-dialog > .modal-content {
  border-radius: 15px !important;
}
.online_test_modal_head_one > .modal-dialog > .modal-content {
  border-radius: 15px;
}
.success_assign > .modal-dialog > .modal-content {
  border-radius: 15px;
}
.online_test_modal_head > .modal-dialog > .modal-content > .modal-body {
  padding: inherit !important;
}
.online_test_modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0.5rem;
}
.online_test_modal_mid > .check_drop > .MuiIconButton-label > .MuiSvgIcon-root {
  color: #ff8cb0 !important;
  font-size: 36px !important;
  margin: 1px 3px 10px 0px;
}
.online_test_modal_top {
  display: flex;
  flex-direction: column;
}
.online_test_heading {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.online_test_heading > svg {
  margin-right: 10px;
}
.online_test_heading > span {
  font-size: 24px;
  font-weight: 600;
}
.online_test_fields {
  display: grid;
  grid-template-columns: 200px 200px 200px;
  gap: 10px;
  margin-bottom: 1rem;
}
.online_test_fields > div {
  width: 100%;
  margin-bottom: 0 !important;
}
.online_test_fields > div > div {
  margin-bottom: 0 !important;
}

.online_test_modal_mid {
  display: flex;
  align-items: center;
}
.online_test_modal > .online_test_modal_mid > div {
  margin-right: 16px !important;
}
.online_test_modal_bot {
  display: flex;
  align-items: center;
  justify-content: center;
}
.online_test_modal_cancel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: #ff8cb080;
  border-radius: 16px;
  border: 1px solid #ff8cb080;
  width: 124px;
  height: 56px;
  color: #ff6f9d;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-right: 18px;
}
.online_test_modal_cancel:hover {
  background: #fff;
  color: #ff6f9d;
  transition: all 0.3s ease-in;
}
.online_test_modal_save {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: #ff8cb0;
  border-radius: 16px;
  border: 1px solid #ff8cb0;
  width: 124px;
  height: 56px;
  color: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}
.online_test_modal_save:hover {
  background: #fff;
  color: #ff6f9d;
  transition: all 0.3s ease-in;
}
.online_test_modal_head > .modal-dialog > .modal-content > .modal-body {
  border-radius: 15px;
}

@media (max-width: 700px) {
  .practise_assessment_fields {
    grid-template-columns: 100%;
    margin-bottom: 20px;
  }
  .practise_assessment_modal_head {
    margin-left: 0 !important;
  }
  .practise_assessment_modal_mid {
    flex-direction: column;
  }
  .practise_assessment_modal_cancel {
    margin-right: 10px;
  }
  .practise_assessment_modal
    > .practise_assessment_modal_top
    > .practise_assessment_fields
    > div {
    margin-left: 0 !important;
  }

  .online_test_modal_head {
    margin-left: 0 !important;
  }
  .online_test_modal_mid {
    flex-direction: column;
  }
  .online_test_modal_cancel {
    margin-right: 10px;
  }
  .online_test_fields {
    grid-template-columns: 100%;
  }
  .online_test_modal > .online_test_modal_top > .online_test_fields > div {
    margin-left: 0 !important;
  }
  .practise_assessment_modal_head > .dropdown > button {
    width: 100%;
  }
  .online_test_modal_head > .dropdown > button {
    width: 100%;
  }
}
