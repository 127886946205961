.analytics_main_section {
  width: 100% !important;
  display: contents;
  position: relative;
}
.mini-card-content-heading {
  text-align: center;
}
.analytics_subject_card {
  display: flex;
  position: relative !important;
  width: 80% !important;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  /* margin-bottom: auto; */
  padding: 10px;
  box-shadow: 0px 0px 7px 0px #00000040;
  /* max-height: 1200px;
  overflow-y: scroll; */
}
.analytics_section_layer {
  display: flex !important;
  flex-direction: row !important;
  width: 100% !important;
  justify-content: space-evenly;
  z-index: -2;
}
.analytics_bar_card {
  display: flex;
  position: relative !important;
  width: 42% !important;
  flex-direction: column;
  align-items: center;
  border-radius: 2%;
  justify-content: center;
  margin-left: 30px;
  margin-bottom: 30px;
  padding: 10px;
  box-shadow: 0px 0px 7px 0px #00000040;
}
.analytics_pie_card {
  display: flex;
  position: relative !important;
  width: 42% !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2%;
  margin-left: 30px;
  margin-bottom: 30px;
  padding: 10px;
  box-shadow: 0px 0px 7px 0px #00000040;
}
.analytics_card_heading {
  display: flex;
  justify-content: center;
}
.analytics_card_list {
  display: flex;
  position: absolute;
  top: 50px;
  right: 75px;
  z-index: -1;
  flex-direction: column;
}
.analytics_mini_cards {
  display: flex;
  width: 100% !important;
  justify-content: center !important;
  flex-direction: row;
  margin-bottom: 1vh;
  margin-top: 1vh;
}
.mini_card_icon {
  display: flex;
  justify-content: center;
  width: 30px;
  min-height: 25px;
  margin-right: 10px;
}
.mini_card_layer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mini_card {
  display: flex;
  max-width: 200px !important;
  flex-direction: row;
  justify-content: center;
  border-radius: 10%;
  margin-top: 2%;
  padding: 1%;
  width: 150px;
  height: auto;
  box-shadow: 0px 4px 90px 0px rgba(163, 171, 185, 0.24);
}
.Analytics_Responsive_Container {
  min-width: 387px;
  min-height: 387px;
  justify-content: center;
}
.graph_loaders {
  display: flex;
  position: absolute;
  top: 50%;
  right: 0;
  left: 50%;
  bottom: 0;
  margin: auto;
}
.no_data {
  color: #d9d9d9;
  display: flex;
  position: absolute;
  top: 50%;
  right: 0;
  left: 41%;
  bottom: 0;
  margin: auto;
}
.analytics_mini_cards_section {
  display: flex;
  flex-direction: row;
  max-width: 500px !important;
  justify-content: space-evenly;
}
.analytics_mini_cards_section:last-child {
  margin-left: -8rem;
}
.mini_card:nth-child(2n) {
  margin-left: 2rem;
}
@media screen and (max-width: 1100px) {
  .analytics_main_section {
    font-size: small;
  }
  .analytics_section_layer {
    flex-direction: column !important;
    margin-left: 1rem !important;
  }
  .analytics_pie_card {
    margin-left: 2rem !important;
    width: 90% !important;
  }
  .analytics_bar_card {
    width: 100% !important;
    margin-left: 2rem !important;
  }
  .Analytics_Responsive_Container {
    min-width: 300px;
    min-height: 300px;
  }
  .analytics_mini_cards_section:last-child {
    margin-left: 1rem;
  }
}
@media screen and (max-width: 540px) {
  .classanalytics_card_list {
    top: 125px !important;
  }
  .analytics_main_section {
    font-size: x-small;
  }
  .analytics_mini_cards {
    flex-direction: column;
  }
  .mini_card {
    margin-left: 1rem;
  }
  .Analytics_Responsive_Container {
    min-width: 250px !important;
    min-height: 330px !important;
  }
  .analytics_bar_card{
    min-height: 400px !important;
    font-size: 0.7rem !important;
  }
  
  .analytics_section_layer {
    width: 95% !important;
    margin-left: -0.2rem !important;
  }
  .analytics_mini_cards_section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .analytics_mini_cards_section:last-child {
    margin-left: 0rem;
  }
}
@media screen and (max-width: 400px) {
  .analytics_main_section {
    font-size: 7px;
  }
  .analytics_section_layer {
    width: 95% !important;
    margin-left: -0.5rem !important;
  }
}
