.assignedTestDetails{
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
    border-radius: 10px;
}
.assignedTestDetails_top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}
.assignedTestDetails_bottom{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 25px;
}
.assignedTestDetails_info{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 150px;
}
.assignedTestDetails_type{
    font-size: 16px;
    font-weight: 600;
    color: #FF8CB0;
}
.assignedTestDetails_value{
    font-weight: 400;
    font-size: 16px;
}
@media (max-width: 450px) {
    .assignedTestDetails_value{
        font-weight: 400;
        font-size: 14px;
    }   
}