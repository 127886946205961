input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  /* appearance: none; */
  margin: 0;
}

.onlineTest_up {
  width: 90%;
  margin: auto;
}
.onlineTest {
  display: flex;
  flex-direction: column;
  border-radius: 9px;
  border: 2px dashed #ff8cb0;
  padding: 10px 15px;
}
.onlinetest_marks {
  width: 127px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #f1f1fa;
  box-sizing: border-box;
  border-radius: 10px !important;
  display: flex;
  margin-left: 10px !important;
}

.onlinetest_marks > span {
  display: flex;
  flex: 50% 1;
  align-items: center;
  color: #91919f;
  font-size: 12px;
  justify-content: center;
  text-align: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ques_marks {
  width: 127px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #f1f1fa;
  box-sizing: border-box;
  border-radius: 10px !important;
  display: flex;
  margin-top: 62px !important;
}

.ques_marks > span {
  display: flex;
  flex: 50% 1;
  align-items: center;
  color: #91919f;
  font-size: 12px;
  justify-content: center;
  text-align: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.input-attach-group2 {
  width: 100% !important;
}

.input-attach-group2 > .form-control {
  padding: 0.75rem 0.75rem !important;
  border: 1px solid #f1f1fa;
  border-radius: 16px !important;
}
.add_option_section > .input-attach-group2 {
  width: 85% !important;
}
.onlineTest_Card {
  display: flex;
}
/* modal css */
.randomQues_modal {
  display: flex;
  flex-direction: column;
}
.randomQues_modal_top {
  display: flex;
  font-size: 24px;
  font-weight: 500;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.randomQues_modal_top > svg {
  margin-right: 20px;
}
.randomQues_modal_mid {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  margin-top: 15px;
}
.ques_input {
  display: flex;
  width: 100%;
  background: #ffc5d8;
  border-radius: 5px;
  align-items: center;
  margin-right: 10px;
  margin-left: auto;
  padding: 10px;
  margin-bottom: 15px;
}
.ques_input > span {
  /* margin-left: auto; */
  /* margin-right: auto; */
  font-size: 14px;
  font-weight: 400;
}
.ques_input > input {
  width: 49px;
  height: 28px;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #ffffff;
  /* position: absolute; */
  /* right: 20%; */
  margin-left: auto;
  color: #ff8cb0;
  text-align: center;
}
.quesType_button {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 5px;
  padding: 5px 8px;
  border: 1px solid #ffffff;
  margin-left: auto;
}
.quesType_button > span {
  color: #694e4e69;
  font-size: 10px;
  margin-right: 16px;
}
.test {
  display: flex;
  flex-direction: column;
  width: 218px;
  background-color: #fff;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  padding: 10px;
  margin-top: 172px;
  margin-bottom: 15px;
  position: absolute;
  border-radius: 0px 0px 10px 10px;
}
.test > span {
  content: "";
  background-color: #ff8cb0;
  width: 48.16px;
  height: 3px;
  top: 3px;
  left: 38%;
  position: absolute;
}
.test_op {
  display: flex;
  width: 189px;
  border-radius: 5px;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  padding: 0.75rem 0.5rem;
  border: 1px solid #f1f1fa;
  margin-bottom: 10px;
}
.test_op > span {
  color: #91919f;
  font-size: 10px;
}
.test_op > input {
  width: 38px;
  height: 25px;
  background: #ffc5d8;
  border-radius: 5px;
  border: 1px solid #ffc5d8;
  position: absolute;
  right: 20%;
  color: #000000;
  text-align: center;
}
.randomQues_modal_bot {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
  margin-bottom: 50px;
}
.randomQues_modal_bot_cancel {
  width: 105px;
  height: 40px;
  background: #ffc5d8;
  border-radius: 10px;
  margin-right: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: 1px solid #ffc5d8;
  color: #ff6f9d;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-right: 18px;
  margin-left: 22px;
}

.randomQues_modal_bot_save {
  width: 105px;
  height: 40px;
  background: #ff7fa7;
  border-radius: 10px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: 1px solid #ff7fa7;
  color: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-right: 18px;
}
.libauthor_modal {
  display: flex;
  flex-direction: column;
}
.libauthor_modal_top {
  display: flex;
  font-size: 24px;
  font-weight: 500;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.libauthor_modal_mid {
  display: flex;
  flex-direction: column;
}
.libauthor_modal_mid_up {
  display: flex;
}
.libauthor_modal_bot {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
  margin-bottom: 50px;
}
.lib-modal > .modal-dialog-centered > .modal-content {
  width: 100% !important;
  justify-content: center;
  right: 0px !important;
}
/* .modal-content-center {
    width: 65%;
    margin:0px auto;
} */

.lib-test {
  margin-top: inherit !important;
  margin-left: inherit !important;
  margin-right: inherit !important;
  top: 35%;
  right: 2%;
  width: 253px;
}
.lib-test-down {
  margin-top: inherit !important;
  margin-left: inherit !important;
  margin-right: inherit !important;
  top: 50%;
  right: 2%;
  width: 253px;
}

.marks-input {
  width: 50%;
  background-color: #ff7fa7;
  border: transparent;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
}
.marks-input-blue {
  width: 50%;
  background-color: #90e0ef;
  border: transparent;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
}
.reviewSection {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.submit-onlineTest {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px;
  width: 165px;
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  border-radius: 16px;
  flex-grow: 0;
  margin: 0px 16px;

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}
.review-onlineTest {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px;
  width: 165px;
  background: #ff8cb0;
  border: 1px solid #ff8cb0;
  border-radius: 16px;
  flex-grow: 0;

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}
.assignReviewSection {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.assignModal {
  display: flex;
  flex-direction: column;
  width: 320px;
}
.assignModal_top {
  display: flex;
  font-size: 24px;
  font-weight: 500;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 30px;
  justify-content: space-between;
}
.assignModal_top > div > span {
  font-size: 24px;
  font-weight: 600;
}
.assignModal_mid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.assignModal_mid-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap:10px
}

.dropDown_assign {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px auto;
  width: 50%;
  margin-bottom: 20px;
}
.scrollmenu::-webkit-scrollbar-thumb {
  background: #ff8cb0;
  border-radius: 10px;
}
.colorModal {
  background-color: #ff8cb0 !important;
}
.assignModal_dropdown > button {
  padding: 10px;
  background-color: #ffffff;
  color: #91919f;
  border: 1px solid #f1f1fa;
  width: 100%;
  display: flex;

  align-items: center;
  padding: 12px 16px !important;
  border-radius: 10px !important;
  min-width: 120px;
}
.assignModal_dropdown > button::after {
  margin-top: 1px !important;
}
.assignModal_menu {
  margin-left: -25px !important;
}
.dropDown_assign > .react-datepicker-wrapper {
  visibility: hidden;
}
/* .assignModal_dropdown > .show>.btn-success.dropdown-toggle  */
.closeAssignModal {
  cursor: pointer;
}
.assignCalenderDate {
  padding: 10px !important;
  background-color: #ffffff !important;
  color: #91919f !important;
  border: 1px solid #f1f1fa !important;
  padding-left: 20px !important;
  width: fit-content !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 10px !important;
}
.menu_option1 {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.menu_option1 > div > span {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #fff;
  /* border: 3px solid #4bdf3f; */
  border-radius: 50%;
  right: 15px;
}
/* .menu-option1:hover input ~ span {
    background-color: #4bdf3f;
  } */
.menu_option1:hover input ~ span {
  background-color: #ff8cb0;
}
.colorRed {
  border: 3px solid #ff6f9d !important;
}
.dateStyle {
  visibility: hidden !important;
  width: 0px;
  height: 0px;
}
.assignCalenderDate > img {
  margin-right: 8px;
}
.assignTimeDate {
  padding: 0px;
  background-color: #ffffff;
  color: #91919f;
  border: 1px solid #f1f1fa;
  /* padding-left: 20px; */
  /* width: fit-content; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  /* margin-left: -13px; */
}
.assignTimeDate > .react-time-picker > .react-time-picker__wrapper {
  border: none;
}
.assignTimeDate > img {
  margin-right: 8px;
}
.assignTimeDate > input {
  border: none;
}
.assignModal_bot {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.assignModal_bot > button {
  color: #ffffff;
}
.mobilepart {
  display: none;
}
.fontSize_12 {
  font-size: 12px;
  padding: 5px !important;
}
.Validity_Check_Message {
  font-size: 10px;
  width: auto !important;
  justify-content: center;
}

.image-wrapper {
  display: flex;
  width: 95%;
  max-width: 400px;
  aspect-ratio: 2/2;
  /*margin: auto;*/
  margin: auto;
}
.image-wrapper > img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  image-rendering: -webkit-optimize-contrast; /* For WebKit (Chrome, Safari) */
  image-rendering: -moz-crisp-edges; /* For Firefox */
  image-rendering: -o-crisp-edges; /* For Opera */
  image-rendering: optimizeQuality;
}
@media (max-width: 450px) {
  .fontSize_12 {
    font-size: 10px;
    padding: 5px !important;
  }
  .onlineTest_Card {
    display: flex;
    flex-direction: column;
  }
  .ques_marks {
    margin-top: 0px !important;
    margin-left: auto;
    margin-right: 11px;
  }
  .onlineTest {
    border: none;
  }
  .onlinetest_marks {
    display: none;
  }
  .mobilepart {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .libauth {
    font-size: 8px !important;
  }
  .mobilepart > span {
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    margin-bottom: 10px;
    background: #ffc5d8;
    border: 1px solid #ff8cb0;
    box-sizing: border-box;
    border-radius: 0px 10px 10px 0px;
    color: #ff8cb0;
    font-size: 18px;
  }
}
