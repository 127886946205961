.myResources {
  display: flex;
  flex-direction: row;
}
.myResources .box {
  min-height: 100px;
  background-color: #5ce0d2;
  padding: 25px;
  border-radius: 8px;
}

.myResources .cardData {
  height: fit-content;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.cardData {
  background-color: rgb(233, 213, 213) !important;
}
.boxRow {
  display: flex;
  justify-content: space-between;
}
.boxRowLeft {
  display: flex;
  flex-direction: row;
}
.pathDetail {
  margin-left: 1rem !important;
}
.myResources .cardData p {
  text-decoration: none;
}

.class-subject-text {
  cursor: pointer;
}

/* .class-subject-text h1,
.class-subject-text h4 {
  transition: 0.2s;
}

.class-subject-text h1:hover,
.class-subject-text h4:hover {
  color: white;
} */

/* Model */

.contentModal .contentModalBtnApply {
  background-color: #5ce0d2;
}

.contentModal .contentModalBtnCancel {
  background-color: #ffff;
  color: black;
  border: 2px solid #5ce0d2;
}

.hidden {
  display: none !important;
}

a {
  text-decoration: none !important;
}

.modal_button_container {
  padding: 0.5rem !important;
  padding-bottom: 2rem !important;
}
.modal_button_container > .classModelBtnApply:hover {
  background-color: #5ce0d2;
  color: #ffffff !important;
}

@media screen and (max-width: 500px) {
  /* .modal_button_container { */
  /* margin-bottom: 3rem !important; */
  /* } */
  .cardBox {
    width: 50% !important;
  }
  .card {
    height: 80% !important;
  }
  .boxShadowCard > .text-center > .text-dark {
    width: 80% !important;
  }
}
.spinner {
  display: contents;
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 4px;
  height: 4px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
