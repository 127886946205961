.updates .inputBox {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  min-height: 75px;
  padding: 14px;
  display: flex;
  width: 100% !important;
  justify-content: space-between;
}
.widthset {
  width: 88%;
}
.adjustWidth {
  position: relative;
  left: 53px;
  width: 98%;
}
.dropdown-width {
  min-width: 0px !important;
}
/* .updates .inputBox .inputField {
    width: 74vw;
} */
#input-with-icon-grid {
  width: 60vw !important;
}
.attachIcon_ann {
  margin-right: 15px;
  object-fit: contain !important;
}
.arrowAt {
  display: flex;
  width: 42px;
  height: 42px;
  background-color: rgb(16, 160, 182);
  border-radius: 50%;
  color: white !important;
  justify-content: center;
  align-items: center;
}
.arrowAt > button > span > svg {
  fill: white;
}
.inputAnnounce {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
}
.updates .inputBox .userImage {
  font-size: 60px;
}

.inputBox {
  display: flex;
  flex-direction: row;
}
.inputBox > div > .MuiGrid-root {
  display: flex;
  align-items: center;
}

.inputField {
  border: none;
}
/* .inputBox div div div:nth-child(2) {
    width: 88%;
} */
/* @media (min-width:2500px) and (max-width:2700px) {
    .inputBox div div div:nth-child(2) {
        width: 51%;
    }
}
@media (min-width:2200px) and (max-width:2499px){
    .inputBox div div div:nth-child(2) {
        width: 58%;
    }
}
@media (min-width:1700px) and (max-width:1900px){
    .inputBox div div div:nth-child(2) {
        width: 70%;
    }
}

@media (min-width:550px) and (max-width:780px){
    .inputBox div div div:nth-child(2) {
        width: 69%;
    }
} */
.event_button {
  margin-top: 10px !important;
  background-color: #10a0b6 !important;
  border-radius: 20px !important;
  color: white !important;
  font-style: 10px !important;
  text-transform: none !important;
}
.detailed_modal__mid {
  display: flex;
  align-items: center;
  margin-top: 20px;
  /* justify-content: space-evenly; */
}
.detailed_modal__bottom {
  display: flex;
  justify-content: center;
}
.detailed_modal__mid > div > .detailed_dash {
  margin-left: 20px;
}
.detailed_modal__mid > div > .detailed_dash > .dropdown_button {
  width: 160px !important;
}
.detailed_modal__mid > div > .detailed_dash > .pra-dropdown::before {
  background-color: #10a0b6;
}
.detailed_modal__mid
  > div
  > .detailed_dash
  > .pra-dropdown
  > .menu_option:hover {
  background-color: #baf6ff !important;
}
/* .detailed_modal__mid >div >.detailed_dash>.pra-dropdown>span{
    border:3px solid #10A0B6 !important;
} */
.detailed_modal__mid
  > div
  > .detailed_dash
  > .pra-dropdown
  > .menu_option:hover
  input
  ~ span {
  background-color: #10a0b6;
}
.detailed_modal__mid
  > div
  > .detailed_dash
  > .menu_option_extend::-webkit-scrollbar-thumb {
  background: #10a0b6;
}
.detail_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: #baf6ff !important;
  color: #10a0b6 !important;
  font-weight: 600 !important;
  width: 164px;
  height: 56px;
  border-radius: 16px !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 16px;
}
.detail_button1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: #10a0b6 !important;
  color: #fcfcfc !important;
  font-weight: 600 !important;
  width: 164px;
  height: 56px;
  border-radius: 16px !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 16px;
}
#input-width-icon-grid ::before,
#input-width-icon-grid ::after {
  font-family: "Poppins !important";
}
.Detail_modal > .modal-dialog > .modal-content {
  box-shadow: 0px 4px 7px rgba(33, 33, 33, 0.25);
  border-radius: 15px;
}
.MuiCard-root {
  box-shadow: none !important;
  border: 1px solid #b5b2b2 !important;
  border-radius: 10px !important;
}

.MuiCardHeader-root {
  padding: 10px 15px;
}

.MuiCardHeader-title {
  font-family: "Poppins" !important;
  font-size: 1.2em !important;
  font-weight: bold !important;
}

.MuiCardHeader-subtitle {
  font-family: "Poppins" !important;
}

.MuiCardContent-root {
  padding: 10px !important;
}
.menuIcon {
  font-size: 46px !important;
}
@media only screen and (max-width: 600px) {
  .updates .inputBox .inputField {
    width: auto;
  }
  .updates .inputBox .userImage {
    font-size: 48px;
  }
  .inputBox div div div:nth-child(2) {
    width: 98%;
  }
  .MuiGrid-container {
    flex-wrap: nowrap !important;
  }
  .adjustWidth {
    position: relative;
    left: 12px;
    width: 100%;
  }
  #input-with-icon-grid {
    margin-left: 30px;
  }
  .detailed_modal__mid {
    margin-top: 15px !important;
    flex-direction: column;
    margin: auto;
  }
  .detailed_modal__bottom {
    flex-direction: column;
    margin: auto;
  }
  .detailed_modal__bottom > .detail_button {
    margin-bottom: 15px;
  }
  .detailed_modal__mid > div > .detailed_dash {
    margin-left: 0px;
  }
  .detail_button {
    width: 125px !important;
  }
  .detail_button1 {
    width: 125px !important;
  }
}
@media screen and (max-width: 350px) {
  #input-with-icon-grid {
    margin-left: 0px;
  }
  #input-with-icon-grid {
    width: 55vw !important;
  }
}

.postCard {
  margin-top: 40px;
  overflow-x: hidden;
}

.postCard .input-group input[type="text"] {
  border: 2px solid #e0dcd8;
  border-radius: 40px;
  border-right: none;
}

.postCard .input-group button {
  border: 2px solid #e0dcd8;
  border-radius: 40px;
  border-left: none;
}

.postCard .namePara {
  margin-bottom: 2px;
}

.add_announcement_button {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  margin: 10px auto;
}

.add_announcement_button svg {
  height: 100%;
  width: auto;
}

.react-calendar__tile--active {
  background: #00b68a !important;
  color: white;
  border-radius: 8px;
}

@media only screen and (min-width: 700px) {
  .calendar-width {
    width: 32.333333%;
  }
}
