.panel {
  box-sizing: content-box;
  width: 90% !important;
  padding: 5% !important;
  margin: 0;
  min-width: 90%;
  height: fit-content;
  border-radius: 17px;
  background-color: #ffffff;
}

.panel > .panel-tabs {
  display: flex;
  justify-content: center;
}
.panel > .panel-tabs > .tab {
  width: 122px;
  padding: 5px 0;
  color: #acacac;
  border-bottom: 2px solid #acacac;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
}
.panel > .panel-tabs > .active {
  border-bottom: 2px solid #5ce0d2;
  color: #414141;
}
.panel > .panel-tabs > .tab:nth-child(1) {
  margin-right: 10px;
}

.panel > .panel-pages {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow: hidden;
  scroll-behavior: smooth;
}
.panel > .panel-pages > .page {
  min-width: 100%;
  height: fit-content;
  margin-top: 15px;
}
