.lp-navbar {
  display: flex !important;
  padding: 12px 0 !important;
}

.lp-navbar > .go-back {
  margin: auto auto auto 0;
  cursor: pointer;
}

.lp-navbar > h6 {
  font-size: 1rem;
  width: fit-content;
  color: #000000;
  margin: auto 0;
}
.lp-navbar > .profile {
  display: flex;
  flex-direction: column;
  margin: auto 0 auto auto;
}
.lp-navbar > .profile > img {
  width: 30px;
  height:30px;
  margin: auto;
  border-radius:100%;
}
.lp-navbar > .profile > label {
  font-size: 0.5rem;
}
