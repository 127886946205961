.Login {
    margin: 0px;
    width: 100%;
    height: 60%;
    position: absolute;
    top: 0px;
    /* z-index: -1; */
}

.bg-1 {
    width: 100%;
    height: 150%;
}

.wave {
    position: absolute;
    top: 0%;
    width: 100%;
    height: 55vh;
    z-index: 0;
    background-color: transparent;
    opacity: 0.4;
}