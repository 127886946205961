.question-checker .check-status {
  font-size: 1rem;
  border-radius: 5px;
  color: royalblue;
  height: fit-content;
  text-align: center;
  margin-left:15px;
  margin-right:10px;
  padding:8px 5px;
  border-radius:5px;
  font-family: "Poppins";
  font-weight: bold;
}

.question-checker .check-button {
  margin-left: auto;
  font-weight: bold;
  font-size: 1rem;
  width: 100px;
  padding: 5px 10px; 
  margin-left: 10px;
  border-radius: 10px;
  border: transparent;
  transition: all 0.3s;
  background-color: royalblue;
  color: white;
}

.question-checker .check-button:hover {
  transform: scale(0.9);
}

.question-checker .correct {
  background-color: rgba(172, 255, 47, 0.456);
  color: green;
}

.question-checker .incorrect {
  background-color: rgba(255, 68, 0, 0.412);
  color: red;
}
