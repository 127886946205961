.tableTask .form-check-input:checked {
    background-color: #5ce0d2;
    border-color: #5ce0d2;
}


/* Model */

.urlModel .urlModelBtnApply {
    background-color: #5ce0d2;
}

.urlModel .urlModelBtnCancel {
    background-color: #ffff;
    color: black;
    border: 2px solid #5ce0d2;
}

.resourceModel .resourceModelBtnApply {
    background-color: #5ce0d2;
}

.resourceModel .resourceModelBtnCancel {
    background-color: #ffff;
    color: black;
    border: 2px solid #5ce0d2;
}

.Dropdown-control {
    padding: 3px 2px !important;
    margin: 0 !important;
    font-family: inherit !important;
    font-size: inherit !important;
    line-height: inherit !important;
    width: 90%;
}


/* .MuiInputBase-root {
    background-color: transparent !important;
    border: 2px solid black !important;
    border-radius: 10px;
}

.MuiFilledInput-underline {
    border: none !important;
} */

.work {
    display: flex;
    align-items: center;
}

.modal_button_container {
    padding: 0.5rem;
    padding-bottom: 1rem;
}

@media screen and (max-width: 500px) {
    .work {
        display: block;
        margin: 2rem auto;
    }
    .work div div {
        width: 100% !important;
    }
    .work:nth-child(2) div #addTaskSelector {
        margin-left: 0% !important;
    }
    .modal_button_container {
        padding-bottom: 3rem;
    }
}