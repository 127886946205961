.superhead {
  display: flex;
  flex-direction: column;
}

.fb__head {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-radius: 9px;
  box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
  border-radius: 15px;
}
.fb {
  margin-left: 35px;
  display: flex;
  /* flex-wrap: wrap; */
  padding-left: 30px;
  margin: inherit;
  align-items: flex-start;
  margin-bottom: 20px;
}
.fb__right {
  margin-top: 20px;
  /* margin: auto; */
}
.fb__right > input {
  width: 355px;
  height: 40px;
  margin-bottom: 15px;
  margin-left: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0px 15px;
  background: #cdffe1;
  font-weight: bold;
}
.fbCard {
  width: 100%;
  display: flex;
  align-items: center;
}
.fbCard__left {
  display: flex;
  flex: 2 1;
  padding-left: 18px;
  align-items: flex-start;
  word-break: break-word;
  font-size: 24px;
  font-weight: 600;
}
.fbCard__right {
  /* margin-top: 10px; */
  margin-top: 16px;
  margin-right: 10px;
}
.fbCard__right > svg {
  height: 24px;
  width: 24px;
  margin: 0 5px;
}

.fb_image {
  height: 300px;
  width: 300px;
  border-radius: 15px;
  margin-left: 25px;
  margin-top: 20px;
}
/* .signarture{
    display: flex;
    flex-direction: column;
    padding: 4px 0px 0px 6px;
    width: 225.54px;
    height: 55px;
    background: #FFFFFF;
    border-bottom: 3px solid #59E791;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-right: 18px;
    margin-left: auto;
    order: 2;
}
.last{
    display:flex;
    margin-top: 20px;
    margin-bottom: 10px;
} */
@media screen and (max-width: 450px) {
  .fb {
    flex-wrap: wrap;
  }
  .fb__right > input {
    width: 90%;
    height: 40px;
    margin-top: 15px;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 12px;
    padding: 0px 15px;
    margin-left: 0px;
  }
  .fbCard__left {
    display: flex;
    flex: 2 1;
    padding-left: 18px;
    align-items: flex-start;
    word-break: break-word;
    font-size: 16px;
    font-weight: 600;
  }

  .fb_image {
    margin-left: 0px;
  }
}
