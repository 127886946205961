.submission .box {
    min-height: 100px;
    background-color: #5ce0d2;
    padding: 25px;
    border-radius: 8px;
}

.submission_table {
    box-shadow: none;
    border: 2px solid black;
    border-radius: 10px !important;
}