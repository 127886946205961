.lp-table {
  height: 100%;
  background-color: transparent;
}
.lp-editor {
  box-sizing: content-box;
  overflow-x: none;
}

.lp-table-heading {
  width: calc(100%-60px);
  padding: 20px 30px;
  border-radius: 15px;
  background: #3985fd;
}
.lp-table-heading > p {
  margin: 5px;
  color: #ffffff;
  font-size: 0.9rem;
}
.table-scroller {
  width: 100%;
  overflow-x: scroll;
}
.lp-table-container {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}
.lp-table-container > tr {
  width: 100%;
}
.lp-table-container > tr > td {
    vertical-align: top;
  width: 150px;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 10px 20px;
  color: #2b2e32;
}
.lp-table-container > tr > .index {
 width: fit-content;
}
.lp-table-container > tr > td:last-child {
  width:300px;
  min-width:300px
}
.lp-table-container > .thead {
  margin-bottom: 10px;
}
.lp-table-container > .thead > td {
  background-color: #f3f4f7;
  color: #9aa6b9;
  white-space: nowrap;
  height: fit-content;
}

.lp-table-container > .trow {
  margin-top: 10px;
  border-top: 1px solid #ebebf9;
}
.lp-table-container > .trow {
  height: 150px;
  max-height: auto;
}
.lp-table-container > .trow > td {
  align-content: baseline;
}
