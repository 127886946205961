.analytics_question_card {
  display: flex;
  position: relative !important;
  width: 45% !important;
  flex-direction: column;
  align-items: center;
  border-radius: 2%;
  justify-content: center;
  margin-left: 30px;
  margin-bottom: 30px;
  padding: 10px;
}

.question_analytics_card {
  display: flex;
  position: relative !important;
  width: 90% !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* margin-left: 30px; */
  margin-bottom: 30px;
}

.questionanalytics_section_layer {
  display: flex;
  position: relative !important;
  width: 90% !important;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  margin-left: 60px !important;
  margin-bottom: 30px;
}
.questionanalytics_section_layer:nth-child(1) {
  overflow-y: scroll;
  margin-right: auto;
  max-height: 795px;
  padding-top: 5px;
}
.questionanalytics_section_layer:nth-child(1)::-webkit-scrollbar {
  width: 5px;
}
.questionanalytics_section_layer:nth-child(1)::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #c4c4c459;
  box-shadow: #c4c4c459;
  border-radius: 15px;
}
.questionanalytics_section_layer:nth-child(1)::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background-color: #5ce0d2;
}

.analytics_question_layer {
  display: flex;
  position: relative !important;
  width: 90% !important;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  margin-left: 30px;
  margin-bottom: 30px;
  justify-content: space-evenly;
  padding: 10px;
  box-shadow: 0px 0px 7px 0px #00000040;
}

.option_label {
  border-right: 1px solid #0000001a;
  flex: 0.1 !important;
}

.analytics_question_stat {
  max-width: 200px !important;
  margin-left: 10px;
  margin-bottom: 10px;
  height: 30px;
  background-color: #c4c4c494;
}

.analytics_question_content {
  margin-right: auto;
}

.analytics_question_stats {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.analytics_question_option {
  margin-bottom: 5px;
  margin-left: 5px !important;
}

.analytics_question_content {
  margin-right: 2rem;
}

.analytics_question_fill_in_the_blank {
  margin-top: 25px !important;
  margin-bottom: 25px;
}

.correct_answer_fill_in_the_blank {
  margin-top: 15px !important;
  font-size: smaller;
  font-weight: 700;
}

.option_tof {
  font-weight: 700;
}

.back_btn {
  position: absolute;
  margin-top: 1rem;
  margin-left: 1rem;
  z-index: 999;
}

.Question_Analytics_Responsive_Container {
  min-width: 387px !important;
  min-height: 387px !important;
}

.Question_Analytics_Diversion {
  min-height: 400px;
  min-width: 1px;
  background-color: #000;
}

.questionanalytics_card_list {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 325px;
  right: 75px;
}

.analytics_question_heading {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.analytics_question_heading p:first-child {
  color: #5ce0d2;
  font-size: 1.2em;
  font-weight: bold;
  width: fit-content !important;
}

.analytics_question_heading_card {
  /* border: 1px solid rgba(242, 134, 132, 0.75); */
  font-size: 0.7em;
  width: fit-content !important;
  padding: 5px 2px;
  margin-right: 5px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  /* border: 1px solid #f69792; */
}

.analytics_question_statement {
  font-size: 0.9em;
  margin-bottom: 2rem;
  padding-left: 15px;
}

@media screen and (max-width: 1100px) {
  .question_analytics_card {
    flex-direction: column;
  }
  .Question_Analytics_Diversion {
    display: none;
  }
  .Question_Analytics_Responsive_Container {
    min-width: 300px !important;
    min-height: 300px !important;
  }
  .questionanalytics_section_layer {
    margin-left: 2rem !important;
  }
  .questionanalytics_card_list {
    top: 500px;
    right: 30px;
  }
}

@media screen and (max-width: 540px) {
  .questionanalytics_section_layer {
    margin-left: 1.2rem !important;
  }
}
