.lp-editor {
  box-sizing: content-box;
  overflow-x: none;
}
.lp-editor > .add-row {
  width: fit-content;
  margin-left: auto;
  font-size: 0.9rem;
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 8px 15px;
  cursor: pointer;
  background-color: #efefef;
  color: #9aa6b9;
}
.table-grid {
  width: 100%;
  padding: 0;
  margin: 15px 0 0 0;
  display: flex;
  overflow-x: hidden;
}

.table-grid > .table-grid-head {
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  min-width: 180px;
  text-align: left;
  font-size: 0.9rem;
  background-color: #efefef;
  color: #9aa6b9;
  overflow-x: scroll;
}

.table-grid > .table-grid-head > .table-grid-col {
  box-sizing: content-box !important;
  display: flex;
  width: 150px;
  height: 100px;
  padding: 15px;
  margin: 0;
}

.table-grid > .table-grid-head > .table-grid-col > label {
  width: 120px;
  margin: auto;
}

.table-grid > .table-grid-head > .table-grid-col:nth-child(5) {
  height: 250px;
}

/*table-body*/
.table-grid > .table-grid-body {
  flex: 1;
  box-sizing: content-box;
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  overflow-x: scroll;
}
.table-grid > .table-grid-body > .table-grid-row {
  min-width: 331px;
  box-sizing: content-box !important;
  display: flex;
  flex-direction: column;
}

.table-grid > .table-grid-body > .table-grid-row > div {
  box-sizing: content-box;
  width: 300px;
  height: 100px;
  padding: 15px;
  background-color: #ffffff;
  border-bottom: 1px solid #efefef;
  border-right: 1px solid #efefef;
}

.table-grid > .table-grid-body > .table-grid-row > div:nth-child(5) {
  height: 250px;
}

/*table row text cell style*/
.table-grid > .table-grid-body > .table-grid-row > .text-cell > textarea {
  display: block;
  box-sizing: content-box;
  width: 95%;
  height: 95%;
  padding: 2.5%;
  border-radius: 10px;
  border: transparent;
  font-size: 0.8rem;
  color: #2b2e32;
  font-family: "Poppins";
  text-align: center;
}

.table-grid > .table-grid-body > .table-grid-row > .text-cell > textarea:focus {
  border: 0.5px solid #000000;
}

.table-grid > .table-grid-body > .table-grid-row > .module-cell {
  overflow-x: hidden;
  overflow-y: auto;
}

.module-placeholder {
  box-sizing: content-box;
  width: 85%;
  padding: 30px 5%;
  margin: auto;
  border: 1.5px dashed #c5c5c5;
  border-radius: 16px;
  color: #c5c5c5;
  font-size: 0.9rem;
  text-align: center;
}

.module-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(95% - 40px);
  max-width: 400px;
  max-height: 550px;
  padding: 20px;
  margin: auto;
  border-radius: 20px;
  background-color: #ffffff;
  overflow: hidden;
  z-index: 2;
}

.module-modal-body > .module-modal-contents {
  flex: 1;
  overflow-y: auto;
  margin: 0 0 20px 0;
}

.module-modal-body > .buttons > button {
  text-align: center;
  width: 100px;
  padding: 8px 0;
  margin: auto;
  border-radius: 11px;
  border: transparent;
  background: #5ce0d2;
  color: #ffffff !important;
}
