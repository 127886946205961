.lp-module {
  position: relative;
  box-sizing: content-box;
  display: flex;
  width: (100% - 0.76px);
  margin-bottom: 10px;
  border: 0.38px solid #5ce0d2;
  border-radius: 10px;
}

.lp-module > img {
  width: 90px;
  height: 90px;
  margin: 5px;
  border-radius: 10px;
}

.lp-module > .lp-module-content {
  position: relative;
  box-sizing: content-box;
  padding: 0;
  margin: 0;
  flex: 1;
}

.lp-module > .lp-module-checkbox {
  width: 15px;
  height: 15px;
  border: 1.1px solid #cacdd8;
  margin: auto;
  border-radius: 2px;
  margin-right: 10px;
}

.lp-module > .lp-module-checkbox-active {
  border: transparent;
  background: #5ce0d2;
}

.lp-module > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.lp-module > .lp-module-content > .type {
  position: absolute;
  top: 0;
  right:5px;
  display: inline-block;
  width: 50px;
  height: 15px;
  border-radius: 0 0px 10px 10px;
  text-align: center;
  text-transform: capitalize;
  font-size: 0.6rem;
  background: #5ce0d2;
}
.lp-module > .lp-module-content > .type-check {
  right:-15px;
}

.lp-module > .lp-module-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.lp-module > .lp-module-content > h5 {
  margin-top: 12px;
  margin-bottom: 0;
  font-size: 16px;
}
.lp-module > .lp-module-content > p {
  font-size: 9px;
  margin: 0;
  color: #0d786d;
}

.lp-module > .lp-module-content > p:last-child {
  margin-bottom: 5px;
}
