.practiseAssessment .box .upperBox {
  min-height: 150px;
  background-color: #5ce0d2;
  padding: 25px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
}

.practiseAssessment .box .upperBox .createTest {
  color: black;
}
.className {
  font-weight: 700;
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
}
.assesment_header {
  display: flex;
  flex-direction: row;
  background: #dcfffb !important;
}

.assesment_header__left {
  display: flex;
  width: 54%;
  /* justify-content: space-evenly; */
  margin: 0px 10px;
  margin-top: 35px;
  margin-bottom: 15px;

  flex-wrap: nowrap;
}
.assesment_header__left > div > button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 48px;
  width: 136px;
  left: 337px;
  top: 342px;
  border-radius: 11px;
  background: #beeefc;
  border: 1px solid #beeefc;
  color: #0f93a8;
  margin-bottom: 10px !important;
  margin-left: 2rem !important;
}

.assesment_header__left > button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 48px;
  width: 136px;
  left: 337px;
  top: 342px;
  border-radius: 11px;
  background: #beeefc;
  border: 1px solid #beeefc;
  color: #0f93a8;
  margin-bottom: 10px !important;
  margin-left: 2rem !important;
}

.assesment_header__left > div > button > img {
  height: 20px;
  width: 20px;
}
.assesment_header__left > button > img {
  height: 20px;
  width: 20px;
}
.assesment_header__right {
  display: flex;
  width: 42%;
  align-items: center;
  justify-content: right;
  margin-top: 7px;
  margin-bottom: 15px;
}
.assesment_header__right > .marks {
  height: 65px;
  width: 65px;
  margin: 0px 14px;
  border-radius: 50%;
  background: #0f93a8;
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.assesment_header__right > .selector {
  display: flex;
  flex-direction: column;
}
.assesment_header__right > .selector > .select {
  width: 200px !important;
  height: 37px !important;
  /* background: #FFFFFF; */
  border: 0.5px solid #0f93a8;
  box-sizing: border-box;
  border-radius: 12px;
  color: #0f93a8;
  margin: 5px 0;
}
.assesment_header__right > .selector > .select > option {
  background: #edfcfa;
}
.assesment_header__right > .selector > .form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='rgb(15 147 168)' stroke='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-size: 20px 15px;
}

.assesment_header__right > .selector > .dropdown > .btn-success {
  color: #0f93a8;
  background-color: #ffffff;
  border-color: #198754;
  border: 0.5px solid #0f93a8;
  border-radius: 9px;
  margin-bottom: 5px;
  width: 100%;
  text-align: left;
}
.toggleAuthorHead {
  width: 78px;
  height: 27px;
  background: #dcfffb;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #dcfffb;
  margin: 0px auto;
  display: none;
}
.assesment_header__right > .selector > .dropdown > .dropdown-toggle::after {
  display: inline-block;
  margin-left: inherit;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  right: 5px;
  position: absolute;
  top: 10px;
  margin-right: -2px;
}

.assesment_header__right > .selector > .dropdown > .dropdown-menu {
  min-width: 9.5rem;
  padding: 0.25rem 0.25rem;
  border: 0.5px solid #0f93a8;
  border-radius: 9px;
}

.assesment_header__right
  > .selector
  > .dropdown
  > .dropdown-menu
  > .dropdown-item {
  background-color: #edfcfa;
  margin-bottom: 2px;
  padding: 0.25rem 0.25rem;
}

.assesment_header__right
  > .selector
  > .dropdown
  > .dropdown-menu
  > .dropdown-item
  > span {
  display: block;
  float: right;
  color: #0f93a8;
}
.errorLogs {
  text-decoration: underline !important;
}
.drop-menu {
  min-width: 10rem !important;
}

.drop-menu-list {
  background-color: #d5f5ff !important;
  color: #0f93a8 !important;
}
.scroll {
  fill: #fff !important;
  border-radius: 50%;
  width: 60px !important;
  height: 60px !important;
  background: #5ce0d2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-right: 10px;
}
.scroll_button_head {
  /* position: fixed; */
  position: fixed;
  bottom: 0;
  right: 0;
}

@keyframes moveIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@keyframes moveOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 0;
  }
}

.scroll_button {
  animation: 1200ms moveIn ease-in;
  margin-bottom: 10px;
  cursor: pointer;
}
.scroll_button > span {
  padding: 0px 10px;
  margin-right: 5px;
  width: 79px;
  height: 25px;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 17px;
  color: #858585;
  font-size: 12px;
}
.upload_ques_modal {
  display: flex;
  flex-direction: column;
  width: 450px;
}
.upload_ques_modal__top {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.upload_ques_modal__top > span {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: #212121;
}
.upload_ques_modal__mid {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}
.modal-content {
  width: fit-content !important;
  margin: auto;
}
.upload_done_modal {
  display: flex;
  flex-direction: column;
}
.upload_done_modal__top {
  display: flex;
  font-size: 25px;
  align-items: center;
  font-weight: 400;
}
.upload_done_modal__top > span {
  margin-left: 5px;
  margin-right: 5px;
}
.upload_done_modal__mid {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 16px;
}
.upload_done_modal__mid > svg {
  margin-top: 16px;
}
.upload_done_modal__bottom {
  display: flex;
  justify-content: center;
}
.modal_done {
  background-color: #0f93a8 !important;
  border-radius: 16px !important;
  color: #ffffff !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  position: static;
  width: 164px;
}
.file_input {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.file_input_btn {
  display: flex;
  align-items: center;
  width: 108px;
  height: 50px;
  background: #0f93a8;
  border-radius: 13px 0px 0px 13px;
  font-size: 10px;
  color: #ffffff;
  justify-content: center;
}
.file_input_btn > svg {
  padding-right: 5px;
}
#file-chosen {
  border: 1px dashed #0f93a8;
  border-radius: 0px 13px 13px 0px !important;
  width: 65%;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #9b9b9b;
  padding: 0px 10px;
}
.upload_status {
  font-size: 12px;
  color: #212121;
}
.upload_ques_modal__bottom {
  display: flex;
  align-items: center;
  margin: auto;
}

.export_btn {
  background-color: #0f93a8 !important;
  border-radius: 13px !important;
  padding: 15px 18px !important;
}

.export_btn > svg {
  margin-right: 10px;
}
.navDetail {
  display: flex;
  align-items: center;
}
.modal_cancel {
  background-color: #beeefc !important;
  border-radius: 16px !important;
  color: #0f93a8 !important;
  padding: 16px 32px !important;
  margin-top: 10px;
  margin-right: 10px;
}

.modal_upload {
  background-color: #0f93a8 !important;
  border-radius: 16px !important;
  color: #ffffff !important;
  padding: 16px 32px !important;
  margin-top: 10px;
}

.dropdown > button {
  width: 100%;
  text-transform: capitalize;
}
.dropdown-menu {
  text-transform: capitalize;
}
.slider {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 10px;
}
.slider > svg:hover {
  cursor: pointer;
}
.slider-img {
  width: 60px;
  height: 60px;
}
.floatright {
  display: block;
  float: right;
}
.pageNumber {
  height: 70px;
  width: 70px;
  background: #5ae893;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0px auto;
  font-size: 22px;
  font-weight: 600;
}
.search_div > svg {
  position: relative;
  left: 2rem;
  /* bottom: 23px; */
}
.search_div > svg {
  color: #51e3d4;
  display: none;
}
.search_div {
  /* width: 100%; */
  align-items: center;
  padding-left: 1.1rem !important;
}
.searchBar {
  display: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.search_div :focus {
  margin-right: 5rem !important;
}
.navbar_nots {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-start;
  /* margin-left: 7rem; */
  margin-right: -11rem !important;
}
/* .navbar_nots > svg {
  position: absolute;
} */
@media (min-width: 768px) {
  .practiseAssessment .MuiTabs-flexContainer {
    justify-content: center;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 550px !important;
  }
}
@media screen and (max-width: 450px) {
  .analytics {
    display: none !important;
  }
  .search_div > svg {
    color: #51e3d4;
    display: inline-block;
  }
  .searchBar {
    display: inline-flex;
    /* display: none; */
    background: #ffffff;
    border-radius: 42px;
    border: 1px solid #ffffff;
    padding: 3px;
  }
  .assesment_header {
    width: 100% !important;
    align-items: center;
  }
  .assesment_header__left {
    /* margin-left: auto !important; */
    /* margin-right: 0px; */
    margin-top: 15px;
    transform: scale(0.8);
    /* margin-left: 35px; */
    justify-content: space-evenly;
    width: 100% !important;
  }
  .assesment_header__left > div > button {
    /* width: 60%; */
    font-size: 15px;
    /* width: 55%; */
    width: fit-content;
    font-size: 11px;
    margin-bottom: 10px !important;
    margin-left: 0rem !important;
    margin-right: 1rem;
  }
  .assesment_header__left > button {
    /* width: 60%; */
    width: 90%;
    font-size: 10px;
    margin-bottom: 10px !important;
    margin-left: 1rem !important;
  }
  .assesment_header__right {
    /* margin-left: 75px; */
    transform: scale(0.6);
    margin-right: 24px;
    margin-left: -65px;
  }
  .assesment_header__right > .marks {
    margin: 0px !important;
    margin-right: 6px !important;
    width: 50px;
    height: 50px;
  }
  .assesment_header__right > .selector {
    margin-right: -15px;
  }
  .ps > .MuiTabScrollButton-root.Mui-disabled {
    margin-right: 8%;
  }
  .toggleAuthorHead {
    display: block;
  }
  .scroll_button_head {
    right: 45%;
    left: 45%;
  }
  .assesment_header {
    /* flex-wrap: wrap; */
    justify-content: center;
  }
  .upload_modal > .modal-dialog > .modal-content {
    transform: scale(0.75);
    /* right: 60px; */
  }
}
.shutter_PS > div {
  margin-top: 0px !important;
}
.shutter_PS {
  display: flex;
  justify-content: center;
  margin-bottom: 10% !important;
}
.modal-dialog-centered {
  justify-content: center;
}
