.lesson-plan-grades {
  box-sizing: content-box;
  display:flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
  background-color: #f8f8f8;;
}

.lp-grade-wrapper {
  display: flex;
  width: 100%;
  min-width: 150px;
  max-width: 175px;
  border: 2%;
  margin:10px;
  border-radius: 59px 50px 50px 59px;
}

.lp-grade {
  display: flex;
  width: 100%;
  height: 72px;
  /*background-image: url("../icons/grade-bg-1.svg");*/
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0;
  overflow: hidden;
}
.lp-grade > span {
  margin: auto;
  color: #004D45;
  padding: 10px;
}

.lesson-icon {
  width: fit-content;
  height: fit-content;
  padding: 5px;
  border-radius: 100%;
  margin: auto 10px;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

.lesson-icon > img {
  display: block;
  width: 20px;
  height: 20px;
}
