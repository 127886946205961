.button_container {
  display: flex;
  justify-content: space-between;
  font-family: "Poppins" !important;
}

/*.button_container .button {
  width: fit-content;
  height: fit-content;
  padding: 10px 15px;
  text-align: center;
  border-radius: 5px;
  border: none;
  background: rgba(224, 224, 224, 1);*
  background: rgba(92, 224, 210, 1);
  color: white;
  transition: 0.3s;
}*/

.button_container .button:hover {
  transform: scale(1.05);
}

.button_container > .right-content {
  width: 55%;
  display: flex;
  justify-content: space-between;
}

#button_70 {
  width: fit-content;
  background-image: linear-gradient(#0dccea, #0d70ea);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  padding: 10px 15px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  height: fit-content;
}

.button_container > .right-content > .button {
  margin-left: auto;
}

@media only screen and (max-width:500px) {
  .button_container {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .button_container {
    justify-content: start;
    flex-direction: column;
  }
  .button_container .button {
    font-size: 0.85rem;
  }
  .button_container > .right-content {
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .button_container > .right-content > p {
    margin: auto 0;
  }
  .button_container > .right-content > p > span {
    display: none;
  }
  .button_container > .right-content > .button {
    margin-left: 0;
    margin-right: auto;
  }
}
