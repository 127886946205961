.superhead {
  display: flex;
  flex-direction: column;
}
.remark {
  margin-left: auto;
  border-radius: 8px 8px 0px 0px;
  padding: 8px 21px;
  /* margin-right: 40px; */
  font-size: 15px;
  color: #ffffff;
  width: 30%;
}
.ps_remark {
  background: linear-gradient(90.72deg, #59e792 0%, #72f8a8 100%);
}
.ot_remark {
  background: linear-gradient(90.72deg, #ff8cb0 0%, #ff8cb0 100%);
}
.difficulty_status,
.congnitive_status {
  text-transform: capitalize;
}
.mcq__head {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* border-left: 11px solid #5AE893; */
  border-radius: 9px;
  box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
  border-radius: 15px;
}
.ps_mcq_head {
  border-left: 11px solid #5ae893;
}
.ot_mcq_head {
  border-left: 11px solid #ff8cb0;
}
.mcq {
  display: flex;
  flex-wrap: wrap;
  padding-left: 30px;
  margin: inherit;
}
.mcqCard {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.mcqCard__left {
  display: flex;
  flex: 2 1;
  padding-left: 18px;
  align-items: flex-start;
  word-break: break-word;
  font-size: 24px;
  font-weight: 600;
}
.mcqCard__right {
  /* margin-top: 10px; */
  text-transform: capitalize;
  margin-right: 10px;
  margin-top: 16px;
}
.mcqCard__right > svg {
  height: 24px;
  width: 24px;
  margin: 0 5px;
}
.ot_edit path {
  fill: #ff6a99;
}
.ot_delete path {
  stroke: rgb(255, 106, 153);
}
.checkbox > .MuiIconButton-label > .MuiSvgIcon-root {
  font-size: 42px !important;
}
.ps > .MuiIconButton-label > .MuiSvgIcon-root {
  color: #5ae893 !important;
}
.ot > .MuiIconButton-label > .MuiSvgIcon-root {
  color: #ff6a99 !important;
}

.opt1-container {
  display: block;
  padding: 10px;
  width: 90%;
  height: fit-content;
  padding-left: 44px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  font-weight: bold;
}
/* .opt1-container > div {
  align-items: center;
} */
.ps-op1 {
  border: 1px solid #5eea96;
  border-radius: 10px;
}
.ot-op1 {
  border: 1px solid #ff8cb0;
  border-radius: 10px;
}
/* .ps-op1:hover{
background: #CDFFE1;
} */
/* .ot-op1:hover{
  background: #FFD8E4;
} */
.up {
  display: flex;
  /* flex-direction: row; */
  /* justify-content: space-evenly; */
  /* width: 100%; */
  /* margin-bottom: -12px; */
  /* margin-top: 50px; */
}
/* Hide the browser's default radio button */
.opt1-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 3.5px;
  left: -28px;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
}
.ps_check {
  border: 3px solid #4bdf3f;
}
.ot_check {
  border: 3px solid #ff8cb0;
}
/* On mouse-over, add a grey background color */
/* .opt1-container:hover input ~ .checkmark {
  background-color: #4bdf3f;
} */

/* When the radio button is checked, add a blue background */
/* .opt1-container input:checked ~ .checkmark {
  background-color: #4bdf3f;
  border: 3px solid #4bdf3f;
} */

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.opt1-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
/* .opt1-container .checkmark:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #4bdf3f;
} */

.signarture {
  display: flex;
  flex-direction: column;
  padding: 4px 0px 0px 6px;
  width: 225.54px;
  height: 55px;
  background: #ffffff;
  font-weight: 600;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-top: 30px;
  margin-bottom: 10px;
  margin-left: 18px;
  margin-right: auto;
  order: 2;
}
.imgLogo {
  width: 100px;
  height: 50px;
  margin: 0px auto;
}
.ps_sign {
  border-bottom: 3px solid #59e791;
}
.ot_sign {
  border-bottom: 3px solid #ff8cb0;
}
.icon_res {
  display: flex;
  margin-top: 28px;
  margin-right: 10px;
  order: 2;
  align-items: center;
}
.icon_res > svg {
  height: 24px;
  width: 24px;
  margin: 0 10px;
  cursor: pointer;
}
.last {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
}

.top-left {
  flex: 50%;
  width: 90%;
}

.top-right {
  flex: 50%;
  justify-content: flex-start;
  /* padding-right: 20px; */
}

.bottom-left {
  flex: 50%;
}

.bottom-right {
  flex: 50%;
  justify-content: flex-start;
  /* padding-right: 20px; */
}
.up_img {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 10rem;
}
.top-left-img {
  flex: 50%;
  justify-content: center;
}
.top-right-img {
  flex: 50%;
  justify-content: flex-start;
}
.bottom-left-img {
  flex: 50%;
  justify-content: center;
}
.bottom-right-img {
  flex: 50%;
  justify-content: flex-start;
}
.C {
  display: flex;
}
.mcq_image {
  height: auto;
  width: 300px;
  border-radius: 15px;
  margin-left: 25px;
  margin-top: auto;
  margin-bottom: 30px;
  /* margin-bottom: auto; */
}
.ans_mcq_image {
  width: 130px;
  height: 100px;
  border-radius: 10px;
}
.imgBlock {
  display: flex;
  justify-content: center;
  /* background-size: cover !important; */
  position: relative;
  overflow: hidden;
  min-width: 130px;
  min-height: 100px;
  height: 150px !important;
  border-radius: 10px;
  /* margin-top: 10px; */
  /* margin-left: -34px; */
}
.questionCard {
  width: 80% !important;
}
.onlineQuestionCard {
  width: 100% !important;
}
.scale_img {
  transform: scale(0.7);
}
.difficulty_status {
  margin-right: 10px;
}
.difficulty_status > svg {
  height: 19px;
  width: 19px;
  margin-top: 3px;
}
.congnitive_status > svg {
  height: 18px;
  width: 18px;
  margin-top: -3px;
}
.difficulty_status,
.congnitive_status {
  border-radius: 10px;
  padding: 4px 30px 4px 6px;
  align-items: center;
}
.bg_ps {
  color: #00c550;
  background-color: #cdffe1;
  border: 1px solid #cdffe1;
  font-weight: 600;
}
.bg_ot {
  color: #ff6a99;
  background-color: #ffd8e4;
  border: 1px solid #ffd8e4;
  font-weight: 600;
}
.bg_ot > svg > path {
  fill: #ff6a99;
}
.difficulty_status > svg,
.congnitive_status > svg {
  margin-right: 5px;
}

@media (max-width: 450px) {
  .mcq {
    flex-direction: column;
  }
  .main_div {
    flex-direction: column;
  }
  .mcq_image {
    height: min-content;
    width: 100%;
    border-radius: 15px;
    margin-left: 0px;
    /* margin-top: auto;
    margin-bottom: 30px; */
  }
  .mcqCard__left {
    display: flex;
    flex: 2 1;
    padding-left: 18px;
    align-items: flex-start;
    word-break: break-word;
    font-size: 16px;
    font-weight: 600;
  }
  .signarture {
    display: flex;
    flex-direction: column;
    padding: 4px !important;
    width: fit-content;
    height: fit-content;
    background: #ffffff;
    font-weight: 600;
    font-size: small;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-left: 18px;
    margin-right: auto;
    order: 2;
  }
  .signarture > img {
    width: min-content;
    height: min-content;
  }
  .mcq > .up {
    width: 100% !important;
  }
  .top-left,
  .top-right,
  .bottom-left,
  .bottom-right {
    display: none !important;
  }
  .mcq {
    width: 95% !important;
  }
  /* .top-left,
  .bottom-left {
    margin-left: -15px !important;
  } */
  /* .top-right,
  .bottom-right {
    padding-right: 1px !important;
  } */
  .opt1-container {
    width: 95% !important;
    font-size: 13px !important;
  }
  .remark {
    margin-right: 0px;
    width: 70%;
  }
  /* .mcq > .up {
    width: 50% !important;
    }
  .mcq{
    width: 95%!important;
    display: flex!important;
    flex-direction: column!important;
  }
  .top-right,.bottom-right{
    margin-left: -15px!important;
    padding-right: inherit!important;
  }

  .checkmark {
    top: 10px!important;
    left: 10px!important;
  }

  .opt1-container {
    padding-left: 35px!important;
    font-size: 10px!important;
  } */
  .checkmark {
  }
  .questionCard {
    width: 100% !important;
  }
  .difficulty_status,
  .congnitive_status {
    display: none;
  }
}
