.module-search {
  position: relative;
}
.module-search form {
  margin-left: 10px;
}
.module-search form > div {
  display: flex;
  border-radius: 5px;
  background-color: #dcfffb;
  color: #10A0B6;
  padding: 5px;
}
.module-search form input {
  width: 100%;
  border: transparent;
  background-color: transparent;
  color: #10A0B6;
}
.module-search .disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.module-search > .search-list {
  position: absolute;
  overflow-y: auto;
  right: 0;
  transform: translateY(5px);
  width: 280px;
  max-height: 550px;
  background-color: white;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.08);
  z-index: 1000;
}
.search-list > ul {
  width: 100%;
  padding: 0;
  margin: 0 0 auto 0;
}

.search-list > ul > li {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 2.5% 5%;
  height: fit-content !important;
  margin: 0;
  align-items: normal !important;
  border-bottom: 1px solid #c4c4c4;
  background-color: white;
}
.search-list > ul > li:hover {
  background-color: #dcfffb;
  cursor: pointer;
}
.search-list > ul > li img {
  border-radius: 5px;
  width:50px;
  height:50px;
  border: 1px solid #dcfffb;

}
.search-list > ul > li h5 {
  font-size: 0.9rem !important;
  font-weight: bold;
  margin: 0;
}
.search-list > ul > li  p {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8rem;
  margin-top: 6px;
  margin-bottom: 0px;
}
.search-list > ul > li p > span {
  display: block;
  margin-right: 2px;
}

.search-list > ul > li p > span:nth-child(even) {
  font-weight: 600;
  color: black;
}

.load-more {
  padding: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 0.8rem;
  cursor: pointer;
  background-color: #f1f1f1;
}

@media only screen and (min-width: 700px) {
  .module-search form {
    margin-right: 10px;
  }
  .module-search > .search-list {
    width: 400px;
  }
}
