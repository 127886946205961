.dropdown_button {
    padding: .65rem .75rem!important;
    border: 1px solid #F1F1FA!important;
    border-radius: 16px!important;
    width: 244px;
    text-align: left!important;
    color: #91919F!important;
    font-size: 16px!important;

    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    width: 244px;
    height: 56px;
    border: 1px solid #F1F1FA;
    box-sizing: border-box;
    border-radius: 16px; */
}
.disableColor > .css-1x1mlzx-Control > .css-319lph-ValueContainer > .css-14el2xx-placeholder{
    color: #d1d1d1;
}
.menu_option_extend{
    height: 200px;
    /* overflow-x: hidden; */
    overflow-y: scroll!important;
}

.menu_option_extend::-webkit-scrollbar {
    width: 8px;
    background-color: #fff;
  }
  .selectOptions{
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      padding: 10px 5px;
      cursor: pointer;
  }
  .selectOptions>span{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
      height: 20px;
      width: 20px;
      border-radius: 20px;
      z-index:1000;
      position: relative;
      cursor: pointer;
}
    .psColor>span{
        border:3px solid #4bdf3f !important;
  }
  .psColor:hover{
      background-color: #CDFFE1;
  }
  .psColor:hover>span{
    background-color: #4bdf3f;
  }
  .otColor>span{
    border:3px solid #FF8CB0 !important;
}
.otColor:hover{
  background-color: #FFC5D8;
}
.otColor:hover>span{
background-color: #FF8CB0;
}
.menulistFilter{
    width: 150px !important;
}
.css-g1d714-ValueContainer{
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
}
.css-1rhbuit-multiValue{
    background-color: #ffffff !important;
}
.selectOptions:first-child{
    margin-top: 30px !important;
}
.selectOptions:first-child> .beforeAfterSpan{
    display: block;
    /* background-color: #4bdf3f; */
    position: absolute;
    width: 45px;
    height: 5px;
    left: 50%;
    transform: translate(-50%,0);
    top: 0px;
}
.beforeAfterSpan{
    display: none;
    /* background-color: #4bdf3f; */
}
  /* Track */
  .menu_option_extend::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey;  */
    border-radius: 10px;
  }
  /* Handle */
  .menu_option_extend::-webkit-scrollbar-thumb {
    background: #4bdf3f; 
    border-radius: 15px;
  }
  
  /* Handle on hover */
  .menu_option_extend::-webkit-scrollbar-thumb:hover {
    background: #2e682a; 
  }


.dropdown > .dropdown-menu {
    width: 100%;
    border: none;
    /* padding: 0rem!important; */
}

.dropdown > .dropdown-menu > .dropdown-item {
    width: 100%;
    padding: .50rem .50rem;
    color: #91919F!important;
    border-bottom: 1px solid #91919f14!important;
    font-weight: 100;
}
.menu_option >div>input{
    visibility: hidden;
}
.menu_option_extend > .dropdown-item {
    white-space: inherit!important;
}

.menu_option{
    display: flex!important;
    justify-content: space-between;
    align-items: center;
}
.menu_option > div > span{
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: #fff;
    /* border: 3px solid #4bdf3f; */
    border-radius: 50%;
    right: 15px;
}
.menu_option > div{
    display: flex;
    align-items: center;
}
.menu_option:hover input ~ span {
    background-color: #4bdf3f;
  }
  .menu_option:hover input ~ .onlinecheck{
    background-color: #FF8CB0;
  }
.menu_option input:checked ~ span {
    background-color: #4bdf3f;
}
.menu_option input:checked ~ .onlinecheck{
    background-color: #FF8CB0;
}
.dropdown-toggle::after {
    align-items: center;
    float: right;
    margin-top: 9px;
}

.pra-dropdown {
    padding: 0px 10px!important; 
    position: relative;
}

.pra-dropdown::before {
    content: '';
    position: absolute;
    background-color: #4bdf3f;
    width: 48.16px;
    height: 5px;
    top: 6px;
    left: 38%;
}

.pra-drop-list {
    border:1px solid #91919f14!important;
    border-radius: 8px;
    margin-bottom: 10px;
}

.pra-drop-list:first-child,
.pra-drop-list:first-child > div > span {
    /* background-color: #CDFFE1; */
    margin-top: 40px;
}

.pra-drop-list:first-child > div > span {
    top:11px;
}

.pra-drop-list:hover {
    background-color: #CDFFE1!important;
}

.onl-dropdown {
    padding: 0px 10px!important; 
    position: relative;
}

.onl-dropdown::before {
    content: '';
    position: absolute;
    background-color: #FF8CB0;
    width: 48.16px;
    height: 5px;
    top: 6px;
    left: 38%;
}

.onl-drop-list {
    border:1px solid #91919f14!important;
    border-radius: 8px;
    margin-bottom: 10px;
}

.onl-drop-list:first-child,
.onl-drop-list:first-child > div > span {
    /* background-color: #FFC5D8; */
    margin-top: 40px;
}

.onl-drop-list:hover {
    background-color: #FFC5D8!important;
}

.onl-drop-list:first-child > div > span {
    top:11px;
}

.edit_option > div > span {
    /* position: absolute;
    height: 20px;
    width: 20px; */
    background-color: #fff;
    border: 3px solid #0F93A8;
    /* border-radius: 50%;
    right: 15px; */
}
.edit_option:hover input ~ span {
    background-color: #0F93A8;
}
.edit_option input:checked ~ span {
    background-color: #0F93A8;
}

.edit_option:hover {
    background-color: #DDF1F3!important;
}

.edit-dropdown::before {
    background-color: #DDF1F3!important;
}
