.test_details_top {
    background-color: #5ce0d2;
}

#test_name {
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-size: 2em;
    font-weight: bold;
}

#test_description {
    margin-block-start: 5px;
}


/* For questios in the test  */

.question_field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.question_question {
    padding: 10px;
    font-size: 1.2em;
    font-weight: bold;
    width: fit-content;
}

.question_option {
    display: flex !important;
    flex-direction: row;
}

.question_option p {
    padding: 0px 12px;
}